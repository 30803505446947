import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useDispatch } from "react-redux";
import { loginUser } from "src/helpers/store/Actions/auth";
import { IntlContext } from "src/App";
import { ReactComponent as BackArrow } from "../../../../assets/images/Back_Arrow.svg";

const bookmakersList = [
  { id: 1, name: "Bet365" },
  { id: 2, name: "Betgold" },
  { id: 3, name: "PlayUp" },
  { id: 4, name: "Elitebet" },
  { id: 5, name: "UniBet" },
  { id: 6, name: "Gallopbet" },
  { id: 7, name: "TopSport" },
  { id: 8, name: "VICBet" },
  { id: 9, name: "BlueBet" },
  { id: 10, name: "Unchecked" },
  { id: 11, name: "BoomBet" },
  { id: 12, name: "TAB" },
  { id: 13, name: "Southern Cross Bet" },
  { id: 14, name: "SportsBet" },
  { id: 15, name: "Palmerbet" },
  { id: 16, name: "Betfair" },
  { id: 17, name: "Bbet" },
  { id: 18, name: "Winbet" },
  { id: 19, name: "Pointsbet Australia" },
  { id: 20, name: "Other - Please specify" }
];
function BookmakersEdit(data) {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [newData, setnewData] = useState(data?.data);
  const [checkboxValue, setcheckboxValue] = useState(newData?.bookMaker);
  const [bookMakerOther, setbookMakerOther] = useState(
    newData?.bookMakerOther?.join("")
  );
  const [isLoading, setisLoading] = useState(false);

  const handleOnChange = e => {
    const { value, checked } = e.target;
    if (checked === true) {
      let checkboxdata = [...checkboxValue, value];
      setcheckboxValue(checkboxdata);
      // setnewData([])
    } else {
      let checkboxdata = checkboxValue?.filter(element => element !== value);
      setcheckboxValue(checkboxdata);
    }
  };

  const handleBookmakerOther = e => {
    setbookMakerOther(e.target.value);
  };
  const handleSave = async () => {
    setisLoading(true);
    const payload = {
      bookMaker: checkboxValue ? checkboxValue : "",
      bookMakersOther: checkboxValue?.includes("Other - Please specify")
        ? bookMakerOther
          ? [bookMakerOther]
          : []
        : [],
      sportOrEvent: newData?.sportOrEvent,
      sportOrEventOther: newData?.sportOrEventOther,
      NotificationPreferences: newData?.NotificationPreferences
      // offerings: newData?.offerings,
      // offeringsOther: newData?.offeringsOther
    };

    try {
      const { status, data } = await axiosInstance.put(
        "user/editProfile",
        payload
      );
      if (status === 200) {
        // setuserData(data);
        setisLoading(false);
        navigate("/profile");
      } else if (status === 403) {
        setisLoading(false);
        localStorage.removeItem("auth_token");
        navigate("/sign-in");
        dispatch(loginUser(""));
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/profile");
  };
  return (
    <>
      <Box>
        <Box className="useredit-details" onClick={() => handleBack()}>
          <span>
            <BackArrow className="back-arrow" />
          </span>
          <h4>{localesData?.EDITPROFILE?.EDIT_BOOKMAKER_ACCOUNTS}</h4>
        </Box>
        <Box className="checkbox-list">
          <Box className="checkbox-list-1">
            {bookmakersList?.map(element => (
              <FormControlLabel
                className="bookmakers-check"
                key={element.id}
                onChange={e => handleOnChange(e)}
                control={<Checkbox className="bookmakers-checkbox" />}
                label={element?.name}
                value={element?.name}
                name="bookmakers"
                checked={checkboxValue?.includes(element?.name)}
              />
            ))}
          </Box>
          <Box className="other-filed filed-1 ">
            <Box></Box>
            <Box>
              {checkboxValue?.includes("Other - Please specify") ? (
                <Box>
                  <TextField
                    placeholder={""}
                    variant="outlined"
                    type="text"
                    name="OtherPleaseSpecify"
                    className="text-field"
                    value={bookMakerOther}
                    onChange={e => handleBookmakerOther(e)}
                  />
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Box>

        <Box className="profile-button">
          <Box className="profile-btn">
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={() => handleBack()}
            >
              {localesData?.EDITPROFILE?.CANCEL}
            </Button>
            <Button
              variant="contained"
              className="btn-save"
              onClick={() => handleSave()}
            >
              {localesData?.EDITPROFILE?.SAVE}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default BookmakersEdit;
