import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useDispatch } from "react-redux";
import { loginUser } from "src/helpers/store/Actions/auth";
import { IntlContext } from "src/App";
import { ReactComponent as BackArrow } from "../../../../assets/images/Back_Arrow.svg";

const sportsList = [
  { id: 1, name: "Horses" },
  { id: 2, name: "Cycling" },
  { id: 3, name: "Netball" },
  { id: 4, name: "Greyhounds" },
  { id: 5, name: "Darts" },
  { id: 6, name: "Novelties and Entertainment" },
  { id: 7, name: "Harness" },
  { id: 8, name: "e - Sports" },
  { id: 9, name: "Politics" },
  { id: 10, name: "American Football" },
  { id: 11, name: "GAA Matches" },
  { id: 12, name: "Rugby League" },
  { id: 13, name: "Australian Rules" },
  { id: 14, name: "Golf" },
  { id: 15, name: "Rugby Union" },
  { id: 16, name: "Baseball" },
  { id: 17, name: "Handball" },
  { id: 18, name: "Snooker" },
  { id: 19, name: "Basketball - Aus/Other" },
  { id: 20, name: "Ice Hockey - Other" },
  { id: 21, name: "Soccer" },
  { id: 22, name: "Basketball - US" },
  { id: 23, name: "Ice Hockey - US" },
  { id: 24, name: "Sports Novelties" },
  { id: 25, name: "Boxing" },
  { id: 26, name: "Lacrosse" },
  { id: 27, name: "Table Tennis" },
  { id: 28, name: "Chess" },
  { id: 29, name: "Megabet" },
  { id: 30, name: "Tennis" },
  { id: 31, name: "Cricket" },
  { id: 32, name: "Motor Racing" },
  { id: 33, name: "UFC - MMA" },
  { id: 34, name: "Other - Please specify" }
];

function SportsEdit(data) {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [newData, setnewData] = useState(data?.data);
  const [selectedSports, setselectedSports] = useState(newData?.sportOrEvent);
  const [sportsOther, setsportsOther] = useState(
    newData?.sportOrEventOther?.join("")
  );
  const [isLoading, setisLoading] = useState(false);
  const handleSelectedSports = e => {
    const { value, checked } = e.target;
    if (checked === true) {
      let checkboxdata = [...selectedSports, value];
      setselectedSports(checkboxdata);
    } else {
      let checkboxdata = selectedSports?.filter(element => element !== value);
      setselectedSports(checkboxdata);
    }
  };
  const handlesportsOther = e => {
    setsportsOther(e.target.value);
  };
  const handleSave = async () => {
    setisLoading(true);
    const payload = {
      sportOrEvent: selectedSports ? selectedSports : "",
      sportOrEventOther: selectedSports?.includes("Other - Please specify")
        ? sportsOther
          ? [sportsOther]
          : []
        : [],
      NotificationPreferences: newData?.NotificationPreferences,
      // offerings: newData?.offerings,
      // offeringsOther: newData?.offeringsOther,
      bookMaker: newData?.bookMaker,
      bookMakersOther: newData?.bookMakerOther
    };

    try {
      const { status, data } = await axiosInstance.put(
        "user/editProfile",
        payload
      );
      if (status === 200) {
        // setuserData(data);
        setisLoading(false);
        navigate("/profile");
      } else if (status === 403) {
        setisLoading(false);
        localStorage.removeItem("auth_token");
        navigate("/sign-in");
        dispatch(loginUser(""));
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/profile");
  };
  return (
    <>
      <Box>
        <Box className="useredit-details" onClick={() => handleBack()}>
          <span>
            <BackArrow className="back-arrow" />
          </span>
          <h4>{localesData?.EDITPROFILE?.EDIT_SPORTS}</h4>
        </Box>
        <Box className="checkbox-list">
          <Box className="checkbox-list-2">
            {sportsList?.map(element => (
              <FormControlLabel
                className="bookmakers-check"
                key={element.id}
                onChange={e => handleSelectedSports(e)}
                control={<Checkbox className="bookmakers-checkbox" />}
                label={element?.name}
                value={element?.name}
                name="sport"
                checked={selectedSports?.includes(element?.name)}
              />
            ))}
          </Box>
          <Box className="other-filed filed-2">
            <Box className="sport-filed"></Box>
            <Box>
              {selectedSports?.includes("Other - Please specify") ? (
                <Box>
                  <TextField
                    placeholder={""}
                    variant="outlined"
                    type="text"
                    name="OtherPleaseSpecify"
                    className="text-field"
                    value={sportsOther}
                    onChange={e => handlesportsOther(e)}
                  />
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Box>

        <Box className="profile-button">
          <Box className="profile-btn">
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={() => handleBack()}
            >
              {localesData?.EDITPROFILE?.CANCEL}
            </Button>
            <Button
              variant="contained"
              className="btn-save"
              onClick={() => handleSave()}
            >
              {localesData?.EDITPROFILE?.SAVE}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SportsEdit;
