import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { Config } from "../../../helpers/context/config";
import DynamicScript from "../DynamicScript/DynamicScript";
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Box } from "@material-ui/core";
// import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";

const AdBannner = ({
  placeholder,
  addetails,
  height,
  margin,
  onAdVisible,
  position,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const adRef = useRef(null);
  const [isAdVisible, setIsAdVisible] = useState(false);
  const [AdsIds, setAdsIds] = useState([]);

  const handleAdsImression = async (id) => {
    try {
      const payload = {
        id: id,
        position_id: [position],
      };
      const { status, data } = await axiosInstance.post(
        `addImpression`,
        payload
      );
      if (status === 200) {
      }
    } catch (err) { }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // if (entries[0].isIntersecting && !isAdVisible) {
        //   console.log(`Ad ${addetails?.id} appeared on screen!`);
        //   setIsAdVisible(true);
        //   onAdVisible(addetails?.id);
        //   if (!AdsIds?.includes(addetails?.id)) {
        //     handleAdsImression(addetails?.id);
        //   }
        // }
        const adElement = entries[0];
        const isIntersecting = adElement.isIntersecting;
        const isVisible = isIntersecting && adElement.intersectionRatio > 0;
        const adElementHeight = adElement.boundingClientRect.height;
        if (isVisible && adElementHeight > 1 && !isAdVisible) {
          setIsAdVisible(true);
          onAdVisible(addetails?.id);
          if (!AdsIds?.includes(addetails?.id)) {
            handleAdsImression(addetails?.id);
          }
        }
      },
      {
        rootMargin: "0px",
        threshold: 0.1, // 50% of the element is visible
      }
    );

    if (adRef.current) {
      observer.observe(adRef.current);
    }

    return () => {
      if (adRef.current) {
        observer.unobserve(adRef.current);
      }
    };
  }, [addetails?.id, adRef, isAdVisible, onAdVisible]);

  const handleNavigate = () => {
    navigate("/advertisingenquiries", { state: location });
  };

  const handleClick = async (id) => {
    try {
      const payload = {
        id: id,
        position_id: position,
      };
      const { status, data } = await axiosInstance.post(`addClick`, payload);
      if (status === 200) {
      }
    } catch (err) { }
  };

  if (addetails) {
    return addetails?.MediaDetail?.type === "image" &&
      addetails?.MediaDetail?.image !== "" ? (
      <a
        href={addetails?.Campaign?.url}
        target="_blank"
        rel="noopener noreferrer"
        className="advt-wrap"
      >
        <Box ref={adRef}>
          <img
            style={{ margin: margin ? "0px" : "" }}
            src={
              addetails?.MediaDetail?.image?.includes("uploads")
                ? Config.countryMediaURL + addetails?.MediaDetail?.image
                : addetails?.MediaDetail?.image
            }
            onClick={() => handleClick(addetails?.id)}
            alt="ad-banner"
            className="ad-banner"
            id={`advt_${addetails?.id}`}
            // loading="lazy"
            effect="blur"
            width="100%"
          />
        </Box>
      </a>
    ) : addetails?.MediaDetail?.type === "script" &&
      addetails?.MediaDetail?.script !== "" ? (
      <a
        href={addetails?.Campaign?.url}
        target="_blank"
        rel="noopener noreferrer"
        className="advt-wrap"
      >
        <DynamicScript
          dynamicscript={addetails?.MediaDetail?.script}
          addetails={addetails}
          handleClick={handleClick}
          margin={margin}
          adRef={adRef}
        />
      </a>
    ) : (
      //   <a href={addetails?.Campaign?.url} target="_blank" rel="noopener noreferrer">
      <img
        style={{ margin: margin ? "0px" : "" }}
        className="ad-banner"
        src={placeholder}
        alt="banner"
        onClick={() => handleNavigate()}
        // loading="lazy"
        effect="blur"
      />
      //   </a>
    );
  } else {
    return (
      //   <a href="#">
      <img
        style={{ margin: margin ? "0px" : "" }}
        className="ad-banner"
        src={placeholder}
        alt="banner"
        onClick={() => handleNavigate()}
        // loading="lazy"
        effect="blur"
      />
      //   </a>
    );
  }
};

export default AdBannner;
