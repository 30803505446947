import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Box,
  Chip,
  Breadcrumbs,
  Typography,
  Grid,
  Tabs,
  Tab
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import RaceHorses from "../../../assets/images/Sport/race_hourses.svg";
import RaceGreyhounds from "../../../assets/images/Sport/races_greyhounds.svg";
import RaceHarness from "../../../assets/images/Sport/race_harness.svg";
import banner from "../../../assets/images/banner/smart-b-banner-primary.webp";
import bannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import homeLongBanner from "../../../assets/images/ad-placeholder/homepagelong.webp";
import AboveSports from "../../../assets/images/ad-placeholder/home-above-sport.webp";
import HomeAboveOurPartner from "../../../assets/images/ad-placeholder/home-above-ourpartner.webp";
import HomeAboveOurPartnerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_894x218_4.jpg";
import HomeBelowOurPartner from "../../../assets/images/ad-placeholder/homebelow-ourpartner.webp";
import DefaultImg from "../../../assets/images/smartb_default.png";
import axiosInstance from "../../../helpers/Axios/axiosInstance";
import { fetchAdvtData } from "../../../helpers/store/Actions/Ads";
import { Routes } from "../../../helpers/constants/routeConstants";
import { IntlContext } from "src/App";
import Countdown from "react-countdown";
import moment from "moment-timezone";
import Loader from "../../../components/Loader";
import "./home.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  fetchAFData,
  fetchARData,
  fetchBaseballData,
  fetchBasketballData,
  fetchBoxingData,
  fetchCricketData,
  fetchGolfData,
  fetchIceHockeyData,
  fetchMMAData,
  fetchRLData,
  fetchRUData,
  fetchSoccerData,
  fetchTennisData
} from "src/helpers/store/Actions/TeamSportEvents";
import { URLS } from "../../../helpers/constants/urls";
import AdvertisingBannner from "../advertisingBanner";
import OurPartners from "../ourPartners";
import { Config } from "../../../helpers/context/config";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";
import AdBannner from "../AdBanner/AdBanner";
import logo from "../../../assets/images/logo.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ScriptLoader from "../scriptLoader/ScriptLoader";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import { ReactComponent as NewsIcon } from "../../../assets/images/icons/Newsicon.svg";
import { ReactComponent as RacingIcon } from "../../../assets/images/icons/Racingicon.svg";
import { ReactComponent as SportIcon } from "../../../assets/images/icons/sporticon.svg";
import { ReactComponent as NewsIconActive } from "../../../assets/images/icons/Newsicon-active.svg";
import { ReactComponent as RacingIconActive } from "../../../assets/images/icons/Racingicon-active.svg";
import { ReactComponent as SportIconActive } from "../../../assets/images/icons/sporticon-active.svg";
import { ReactComponent as Watch } from "src/assets/images/watch.svg";

import { ReactComponent as AmericanFootball } from "../../../assets/images/SportIcon/outlineSportIcon/AmericanFootball.svg";
import { ReactComponent as AustralianRules } from "../../../assets/images/SportIcon/outlineSportIcon/AustralianRules.svg";
import { ReactComponent as Baseball } from "../../../assets/images/SportIcon/outlineSportIcon/Baseball.svg";
import { ReactComponent as Basketball } from "../../../assets/images/SportIcon/outlineSportIcon/Basketball.svg";
import { ReactComponent as Boxing } from "../../../assets/images/SportIcon/outlineSportIcon/Boxing.svg";
import { ReactComponent as Cricket } from "../../../assets/images/SportIcon/outlineSportIcon/Cricket.svg";
import { ReactComponent as Golf } from "../../../assets/images/SportIcon/outlineSportIcon/Golf.svg";
import { ReactComponent as IceHockey } from "../../../assets/images/SportIcon/outlineSportIcon/IceHockey.svg";
import { ReactComponent as MixedMartialArts } from "../../../assets/images/SportIcon/outlineSportIcon/MixedMartialArts.svg";
import { ReactComponent as RugbyLeague } from "../../../assets/images/SportIcon/outlineSportIcon/RugbyLeague.svg";
import { ReactComponent as RugbyUnion } from "../../../assets/images/SportIcon/outlineSportIcon/RugbyUnion.svg";
import { ReactComponent as Soccer } from "../../../assets/images/SportIcon/outlineSportIcon/Soccer.svg";
import { ReactComponent as Tennis } from "../../../assets/images/SportIcon/outlineSportIcon/Tennis.svg";
import OurPartner from "../ourPartners";
import FeaturedNews from "./featuredNews";
import FeaturedRace from "./featuredRace";
import FeaturedSport from "./featuredSport";
import NoDataComp from "../UI/NoData";
import he from "he";
import { release } from "src/helpers/context/release";

const homeTab = [
  {
    id: 1,
    label: "RACING",
    icon: (
      <span>
        <RacingIcon />
      </span>
    ),
    activeicon: (
      <span>
        <RacingIconActive />
      </span>
    )
  },
  {
    id: 2,
    label: "SPORTS",
    icon: (
      <span>
        <SportIcon />
      </span>
    ),
    activeicon: (
      <span>
        <SportIconActive />
      </span>
    )
  },
  {
    id: 0,
    label: "NEWS",
    icon: (
      <span>
        <NewsIcon />
      </span>
    ),
    activeicon: (
      <span>
        <NewsIconActive />
      </span>
    )
  }
];

export default function AllSportsHomeV2() {
  const intlContext = useContext(IntlContext);
  const navigate = useNavigate();
  const localesData = intlContext?.messages;
  const dispatch = useDispatch();
  const silderRef = useRef(null);
  const tabsRef = useRef(null);

  const [horseRaceData, sethorseRaceData] = useState([]);
  const [harnessRaceData, setharnessRaceData] = useState([]);
  const [greyhoundRaceData, setgreyhoundRaceData] = useState([]);
  const [cricketData, setCricketData] = useState([]);
  const [isThreeRaceLoading, setisThreeRaceLoading] = useState(false);
  const [isCricketLoading, setIsCricketLoading] = useState(false);
  const [RLData, setRLData] = useState([]);
  const [isRLLoading, setIsRLLoading] = useState(false);
  const [RUData, setRUData] = useState([]);
  const [isRULoading, setIsRULoading] = useState(false);
  const [NBAData, setNBAData] = useState([]);
  const [isNBALoading, setIsNBALoading] = useState(false);
  const [AFLData, setAFLData] = useState([]);
  const [isAFLLoading, setIsAFLLoading] = useState(false);
  const [ARData, setARData] = useState([]);
  const [isARLoading, setIsARLoading] = useState(false);
  const [TennisData, setTennisData] = useState([]);
  const [isTennisLoading, setIsTennisLoading] = useState(false);
  const [BaseballData, setBaseballData] = useState([]);
  const [isBaseballLoading, setIsBaseballLoading] = useState(false);
  const [golfData, setGolfData] = useState([]);
  const [isGolfLoading, setIsGolfLoading] = useState(false);
  const [IceHockeyData, setIceHockeyData] = useState([]);
  const [isIceHockeyLoading, setIsIceHockeyLoading] = useState(false);
  const [BoxingData, setBoxingData] = useState([]);
  const [isBoxingLoading, setIsBoxingLoading] = useState(false);
  const [MMAData, setMMAData] = useState([]);
  const [isMMALoading, setIsMMALoading] = useState(false);
  const [SoccerData, setSoccerData] = useState([]);
  const [isSoccerLoading, setIsSoccerLoading] = useState(false);
  const [AdData, setAdData] = useState([]);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [isAFLImageLoading, setIsAFLImageLoading] = useState(false);
  const [isARImageLoading, setIsARImageLoading] = useState(false);
  const [isBaseballImageLoading, setIsBaseballImageLoading] = useState(false);
  const [isNBAImageLoading, setIsNBAImageLoading] = useState(false);
  const [isBoxingImageLoading, setIsBoxingImageLoading] = useState(false);
  const [isCricketImageLoading, setIsCricketImageLoading] = useState(false);
  const [isGolfImageLoading, setIsGolfImageLoading] = useState(false);
  const [isIceHockeyImageLoading, setIsIceHockeyImageLoading] = useState(false);
  const [isMMAImageLoading, setIsMMAImageLoading] = useState(false);
  const [isRLImageLoading, setIsRLImageLoading] = useState(false);
  const [isRUImageLoading, setIsRUImageLoading] = useState(false);
  const [isSoccerImageLoading, setIsSoccerImageLoading] = useState(false);
  const [isTennisImageLoading, setIsTennisImageLoading] = useState(false);
  const [SliderData, setSliderData] = useState([]);
  const [homeTabValue, setHomeTabValue] = useState(
    Config.release == "AU" ? 1 : 2
  );
  const [newsData, setNewsData] = useState([]);
  const [newsLoading, setNewsLoading] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [homePageTab, setHomePageTab] = useState(homeTab);

  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const events = useSelector(state => state?.reduxData);

  // useEffect(() => {
  //   if (events?.CricketData) {
  //     setCricketData(
  //       events?.CricketData?.sort((a, b) =>
  //         moment(a?.startTime).diff(moment(b?.startTime))
  //       )?.slice(0, 3)
  //     );
  //   }
  //   if (events?.RLData) {
  //     setRLData(
  //       events?.RLData?.sort((a, b) =>
  //         moment(a?.startTime).diff(moment(b?.startTime))
  //       )?.slice(0, 3)
  //     );
  //   }
  //   if (events?.RUData) {
  //     setRUData(
  //       events?.RUData?.sort((a, b) =>
  //         moment(a?.startTime).diff(moment(b?.startTime))
  //       )?.slice(0, 3)
  //     );
  //   }
  //   if (events?.AFData) {
  //     setAFLData(
  //       events?.AFData?.sort((a, b) =>
  //         moment(a?.startTime).diff(moment(b?.startTime))
  //       )?.slice(0, 3)
  //     );
  //   }
  //   if (events?.ARData) {
  //     setARData(
  //       events?.ARData?.sort((a, b) =>
  //         moment(a?.startTime).diff(moment(b?.startTime))
  //       )?.slice(0, 3)
  //     );
  //   }
  //   if (events?.BaseballData) {
  //     setBaseballData(
  //       events?.BaseballData?.sort((a, b) =>
  //         moment(a?.startTime).diff(moment(b?.startTime))
  //       )?.slice(0, 3)
  //     );
  //   }
  //   if (events?.BasketballData) {
  //     setNBAData(
  //       events?.BasketballData?.sort((a, b) =>
  //         moment(a?.startTime).diff(moment(b?.startTime))
  //       )?.slice(0, 3)
  //     );
  //   }
  //   if (events?.BoxingData) {
  //     setBoxingData(
  //       events?.BoxingData?.sort((a, b) =>
  //         moment(a?.startTime).diff(moment(b?.startTime))
  //       )?.slice(0, 3)
  //     );
  //   }
  //   if (events?.GolfData) {
  //     setGolfData(
  //       events?.GolfData?.sort((a, b) =>
  //         moment(a?.startTime).diff(moment(b?.startTime))
  //       )?.slice(0, 3)
  //     );
  //   }
  //   if (events?.IceHockeyData) {
  //     setIceHockeyData(
  //       events?.IceHockeyData?.sort((a, b) =>
  //         moment(a?.startTime).diff(moment(b?.startTime))
  //       )?.slice(0, 3)
  //     );
  //   }
  //   if (events?.MMAData) {
  //     setMMAData(
  //       events?.MMAData?.sort((a, b) =>
  //         moment(a?.startTime).diff(moment(b?.startTime))
  //       )?.slice(0, 3)
  //     );
  //   }
  //   if (events?.SoccerData) {
  //     setSoccerData(
  //       events?.SoccerData?.sort((a, b) =>
  //         moment(a?.startTime).diff(moment(b?.startTime))
  //       )?.slice(0, 3)
  //     );
  //   }
  //   if (events?.TennisData) {
  //     setTennisData(
  //       events?.TennisData?.sort((a, b) =>
  //         moment(a?.startTime).diff(moment(b?.startTime))
  //       )?.slice(0, 3)
  //     );
  //   }
  // }, [events]);

  const elementRef = useRef(null);

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  // getting Horse-Harness-Greyhounds racing data
  const fetchThreeRacing = async () => {
    setisThreeRaceLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        URLS.sportData +
          `?limit=4&isRaceSeeAll=${1}&sportId=${"1,2,3"}&countryId=${""}&stateId=${""}&timezone=${timezone}`
      );
      if (status === 200) {
        setisThreeRaceLoading(false);
        sethorseRaceData(data.data.races[1]);
        setharnessRaceData(data.data.races[2]);
        setgreyhoundRaceData(data.data.races[3]);
      }
    } catch (err) {
      setisThreeRaceLoading(false);
    }
  };
  const fetchUpcomingCricketEvent = async () => {
    setIsCricketLoading(true);
    setIsCricketImageLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/crickets/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&CricketTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`
      );
      if (status === 200) {
        setCricketData(
          data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsCricketLoading(false);
        setTimeout(() => {
          setIsCricketImageLoading(false);
        }, 500);
      }
    } catch (err) {
      setIsCricketLoading(false);
      setIsCricketImageLoading(false);
    }
  };
  const fetchUpcomingRLEvent = async () => {
    setIsRLLoading(true);
    setIsRLImageLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/rls/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&RLTournamentId=&teamId=&timezone=${timezone}&SportId=12&oddCheck=true&marketId=1&limit=3&liveOdd=true&marketname=h2h&isAdmin=false&isHome=true`
      );
      if (status === 200) {
        setRLData(
          data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsRLLoading(false);
        setTimeout(() => {
          setIsRLImageLoading(false);
        }, 500);
      }
    } catch (err) {
      setIsRLLoading(false);
      setIsRLImageLoading(false);
    }
  };
  const fetchUpcomingRUEvent = async () => {
    setIsRULoading(true);
    setIsRUImageLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/rls/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&RLTournamentId=&teamId=&timezone=${timezone}&SportId=13&oddCheck=true&marketId=1&limit=3&liveOdd=true&marketname=h2h&isAdmin=false&isHome=true`
      );
      if (status === 200) {
        setRUData(
          data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsRULoading(false);
        setTimeout(() => {
          setIsRUImageLoading(false);
        }, 500);
      }
    } catch (err) {
      setIsRULoading(false);
      setIsRUImageLoading(false);
    }
  };
  const fetchUpcomingNBAEvent = async () => {
    setIsNBALoading(true);
    setIsNBAImageLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/nba/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&NBATournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`
      );
      if (status === 200) {
        setNBAData(
          data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsNBALoading(false);
        setTimeout(() => {
          setIsNBAImageLoading(false);
        }, 500);
      }
    } catch (err) {
      setIsNBALoading(false);
      setIsNBAImageLoading(false);
    }
  };
  const fetchUpcomingAFLEvent = async () => {
    setIsAFLLoading(true);
    setIsAFLImageLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/afl/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&AFLTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`
      );
      if (status === 200) {
        setAFLData(
          data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsAFLLoading(false);
        setTimeout(() => {
          setIsAFLImageLoading(false);
        }, 500);
        dispatch(fetchAFData(data?.result?.rows));
      } else {
        dispatch(fetchAFData([]));
      }
    } catch (err) {
      setIsAFLLoading(false);
      setIsAFLImageLoading(false);
      dispatch(fetchAFData([]));
    }
  };
  const fetchUpcomingAREvent = async () => {
    setIsARLoading(true);
    setIsARImageLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/ar/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&ARTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`
      );
      if (status === 200) {
        setARData(
          data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsARLoading(false);
        setTimeout(() => {
          setIsARImageLoading(false);
        }, 500);
        dispatch(fetchARData(data?.result?.rows));
      } else {
        dispatch(fetchARData([]));
      }
    } catch (err) {
      setIsARLoading(false);
      setIsARImageLoading(false);
      dispatch(fetchARData([]));
    }
  };
  const fetchUpcomingTennisEvent = async () => {
    setIsTennisLoading(true);
    setIsTennisImageLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/tennis/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&TennisTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`
      );
      if (status === 200) {
        setTennisData(
          data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsTennisLoading(false);
        setTimeout(() => {
          setIsTennisImageLoading(false);
        }, 500);
        dispatch(fetchTennisData(data?.result?.rows));
      } else {
        dispatch(fetchTennisData([]));
      }
    } catch (err) {
      setIsTennisLoading(false);
      setIsTennisImageLoading(false);
      dispatch(fetchTennisData([]));
    }
  };
  const fetchUpcomingBaseballEvent = async () => {
    setIsBaseballLoading(true);
    setIsBaseballImageLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/baseball/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&BaseballTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`
      );
      if (status === 200) {
        setBaseballData(
          data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsBaseballLoading(false);
        setTimeout(() => {
          setIsBaseballImageLoading(false);
        }, 500);
        dispatch(fetchBaseballData(data?.result?.rows));
      } else {
        dispatch(fetchBaseballData([]));
      }
    } catch (err) {
      setIsBaseballLoading(false);
      setIsBaseballImageLoading(false);
      dispatch(fetchBaseballData([]));
    }
  };
  const fetchUpcomingGolfEvent = async () => {
    setIsGolfLoading(true);
    setIsGolfImageLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/golf/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&GolfTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&isOuright=true&limit=3&liveOdd=true&isAdmin=false&isHome=true`
      );
      if (status === 200) {
        setGolfData(
          data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsGolfLoading(false);
        setTimeout(() => {
          setIsGolfImageLoading(false);
        }, 500);
        dispatch(fetchGolfData(data?.result?.rows));
      } else {
        dispatch(fetchGolfData([]));
      }
    } catch (err) {
      setIsGolfLoading(false);
      setIsGolfImageLoading(false);
      dispatch(fetchGolfData([]));
    }
  };
  const fetchUpcomingIceHockeyEvent = async () => {
    setIsIceHockeyLoading(true);
    setIsIceHockeyImageLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/icehockey/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&IceHockeyTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`
      );
      if (status === 200) {
        setIceHockeyData(
          data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsIceHockeyLoading(false);
        setTimeout(() => {
          setIsIceHockeyImageLoading(false);
        }, 500);
        dispatch(fetchIceHockeyData(data?.result?.rows));
      } else {
        dispatch(fetchIceHockeyData([]));
      }
    } catch (err) {
      setIsIceHockeyLoading(false);
      setIsIceHockeyImageLoading(false);
      dispatch(fetchIceHockeyData([]));
    }
  };
  const fetchUpcomingBoxingEvent = async () => {
    setIsBoxingLoading(true);
    setIsBoxingImageLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/boxing/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&BoxingTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`
      );
      if (status === 200) {
        setBoxingData(
          data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsBoxingLoading(false);
        setTimeout(() => {
          setIsBoxingImageLoading(false);
        }, 500);
        dispatch(fetchBoxingData(data?.result?.rows));
      } else {
        dispatch(fetchBoxingData([]));
      }
    } catch (err) {
      setIsBoxingLoading(false);
      setIsBoxingImageLoading(false);
      dispatch(fetchBoxingData([]));
    }
  };
  const fetchUpcomingMMAEvent = async () => {
    setIsMMALoading(true);
    setIsMMAImageLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/mma/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&MMATournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`
      );
      if (status === 200) {
        setMMAData(
          data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsMMALoading(false);
        setTimeout(() => {
          setIsMMAImageLoading(false);
        }, 500);
        dispatch(fetchMMAData(data?.result?.rows));
      } else {
        dispatch(fetchMMAData([]));
      }
    } catch (err) {
      setIsMMALoading(false);
      setIsMMAImageLoading(false);
      dispatch(fetchMMAData([]));
    }
  };
  const fetchUpcomingSoccerEvent = async () => {
    setIsSoccerLoading(true);
    setIsSoccerImageLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `public/soccer/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&SoccerTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=5&liveOdd=true&isAdmin=false&isHome=true`
      );
      if (status === 200) {
        setSoccerData(
          data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 5)
        );
        setIsSoccerLoading(false);
        setTimeout(() => {
          setIsSoccerImageLoading(false);
        }, 500);
        dispatch(fetchSoccerData(data?.result?.rows));
      } else {
        dispatch(fetchSoccerData([]));
      }
    } catch (err) {
      setIsSoccerLoading(false);
      setIsSoccerImageLoading(false);
      dispatch(fetchSoccerData([]));
    }
  };
  useEffect(() => {
    if (release[Config.release]?.raceSportId?.length > 0) {
      fetchThreeRacing();
    }
    // fetchUpcomingCricketEvent();
    // fetchUpcomingRLEvent();
    // fetchUpcomingRUEvent();
    // fetchUpcomingNBAEvent();
    // fetchUpcomingAFLEvent();
    // fetchUpcomingAREvent();
    // fetchUpcomingTennisEvent();
    // fetchUpcomingBaseballEvent();
    // fetchUpcomingIceHockeyEvent();
    // fetchUpcomingBoxingEvent();
    // fetchUpcomingMMAEvent();
    // fetchUpcomingSoccerEvent();
    // fetchUpcomingGolfEvent();
    fetchAllSportApi();
    fetchAdsData();
    fetchNews();
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    const homePageTabFilter = homePageTab?.filter(item =>
      release[Config.release]?.homePageTab?.includes(item?.label)
    );
    setHomePageTab(homePageTabFilter);
  }, []);

  const fetchAllSportApi = async () => {
    setIsCricketLoading(true);
    setIsCricketImageLoading(true);
    setIsRLLoading(true);
    setIsRLImageLoading(true);
    setIsRULoading(true);
    setIsRUImageLoading(true);
    setIsNBALoading(true);
    setIsNBAImageLoading(true);
    setIsAFLLoading(true);
    setIsAFLImageLoading(true);
    setIsARLoading(true);
    setIsARImageLoading(true);
    setIsTennisLoading(true);
    setIsTennisImageLoading(true);
    setIsBaseballLoading(true);
    setIsBaseballImageLoading(true);
    setIsIceHockeyLoading(true);
    setIsIceHockeyImageLoading(true);
    setIsBoxingLoading(true);
    setIsBoxingImageLoading(true);
    setIsMMALoading(true);
    setIsMMAImageLoading(true);
    setIsSoccerLoading(true);
    setIsSoccerImageLoading(true);
    setIsGolfLoading(true);
    setIsGolfImageLoading(true);
    try {
      const allSportApiEndPoint = [
        `public/crickets/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&CricketTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`,
        `public/rls/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&RLTournamentId=&teamId=&timezone=${timezone}&SportId=12&oddCheck=true&marketId=1&limit=3&liveOdd=true&marketname=h2h&isAdmin=false&isHome=true`,
        `public/rls/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&RLTournamentId=&teamId=&timezone=${timezone}&SportId=13&oddCheck=true&marketId=1&limit=3&liveOdd=true&marketname=h2h&isAdmin=false&isHome=true`,
        `public/nba/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&NBATournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`,
        `public/afl/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&AFLTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`,
        `public/ar/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&ARTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`,
        `public/tennis/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&TennisTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`,
        `public/baseball/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&BaseballTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`,
        `public/icehockey/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&IceHockeyTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`,
        `public/boxing/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&BoxingTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`,
        `public/mma/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&MMATournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=3&liveOdd=true&isAdmin=false&isHome=true`,
        `public/soccer/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&SoccerTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&marketId=1&limit=5&liveOdd=true&isAdmin=false&isHome=true`,
        `public/golf/event?startDate=${moment()
          .tz(timezone)
          .format(
            "YYYY-MM-DD"
          )}&endDate=&GolfTournamentId=&teamId=&timezone=${timezone}&oddCheck=true&isOuright=true&limit=3&liveOdd=true&isAdmin=false&isHome=true`
      ];

      let cricketInfo,
        RLInfo,
        RUInfo,
        NBAInfo,
        AFLInfo,
        ARInfo,
        tennisInfo,
        baseballInfo,
        iceHockeyInfo,
        boxingInfo,
        mmaInfo,
        soccerInfo,
        golfInfo;
      await Promise.all(
        allSportApiEndPoint.map(async (item, i) => {
          if (release[Config.release]?.sportId?.includes(4)) {
            if (i === 0) cricketInfo = await axiosInstance.get(item);
          }
          if (release[Config.release]?.sportId?.includes(12)) {
            if (i === 1) RLInfo = await axiosInstance.get(item);
          }
          if (release[Config.release]?.sportId?.includes(13)) {
            if (i === 2) RUInfo = await axiosInstance.get(item);
          }
          if (release[Config.release]?.sportId?.includes(10)) {
            if (i === 3) NBAInfo = await axiosInstance.get(item);
          }
          if (release[Config.release]?.sportId?.includes(15)) {
            if (i === 4) AFLInfo = await axiosInstance.get(item);
          }
          if (release[Config.release]?.sportId?.includes(9)) {
            if (i === 5) ARInfo = await axiosInstance.get(item);
          }
          if (release[Config.release]?.sportId?.includes(7)) {
            if (i === 6) tennisInfo = await axiosInstance.get(item);
          }
          if (release[Config.release]?.sportId?.includes(11)) {
            if (i === 7) baseballInfo = await axiosInstance.get(item);
          }
          if (release[Config.release]?.sportId?.includes(17)) {
            if (i === 8) iceHockeyInfo = await axiosInstance.get(item);
          }
          if (release[Config.release]?.sportId?.includes(6)) {
            if (i === 9) boxingInfo = await axiosInstance.get(item);
          }
          if (release[Config.release]?.sportId?.includes(5)) {
            if (i === 10) mmaInfo = await axiosInstance.get(item);
          }
          if (release[Config.release]?.sportId?.includes(8)) {
            if (i === 11) soccerInfo = await axiosInstance.get(item);
          }
          if (release[Config.release]?.sportId?.includes(16)) {
            if (i === 12) golfInfo = await axiosInstance.get(item);
          }
        })
      );

      if (cricketInfo?.status === 200) {
        setCricketData(
          cricketInfo?.data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsCricketLoading(false);
        setTimeout(() => {
          setIsCricketImageLoading(false);
        }, 500);
      }
      if (RLInfo?.status === 200) {
        setRLData(
          RLInfo?.data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsRLLoading(false);
        setTimeout(() => {
          setIsRLImageLoading(false);
        }, 500);
      }
      if (RUInfo?.status === 200) {
        setRUData(
          RUInfo?.data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsRULoading(false);
        setTimeout(() => {
          setIsRUImageLoading(false);
        }, 500);
      }
      if (NBAInfo?.status === 200) {
        setNBAData(
          NBAInfo?.data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsNBALoading(false);
        setTimeout(() => {
          setIsNBAImageLoading(false);
        }, 500);
      }
      if (AFLInfo?.status === 200) {
        setAFLData(
          AFLInfo?.data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsAFLLoading(false);
        setTimeout(() => {
          setIsAFLImageLoading(false);
        }, 500);
      }
      if (ARInfo?.status === 200) {
        setARData(
          ARInfo?.data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsARLoading(false);
        setTimeout(() => {
          setIsARImageLoading(false);
        }, 500);
      }
      if (tennisInfo?.status === 200) {
        setTennisData(
          tennisInfo?.data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsTennisLoading(false);
        setTimeout(() => {
          setIsTennisImageLoading(false);
        }, 500);
      }
      if (baseballInfo?.status === 200) {
        setBaseballData(
          baseballInfo?.data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsBaseballLoading(false);
        setTimeout(() => {
          setIsBaseballImageLoading(false);
        }, 500);
      }
      if (iceHockeyInfo?.status === 200) {
        setIceHockeyData(
          iceHockeyInfo?.data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsIceHockeyLoading(false);
        setTimeout(() => {
          setIsIceHockeyImageLoading(false);
        }, 500);
      }
      if (boxingInfo?.status === 200) {
        setBoxingData(
          boxingInfo?.data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsBoxingLoading(false);
        setTimeout(() => {
          setIsBoxingImageLoading(false);
        }, 500);
      }
      if (mmaInfo?.status === 200) {
        setMMAData(
          mmaInfo?.data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsMMALoading(false);
        setTimeout(() => {
          setIsMMAImageLoading(false);
        }, 500);
      }
      if (soccerInfo?.status === 200) {
        setSoccerData(
          soccerInfo?.data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsSoccerLoading(false);
        setTimeout(() => {
          setIsSoccerImageLoading(false);
        }, 500);
      }
      if (golfInfo?.status === 200) {
        setGolfData(
          golfInfo?.data?.result?.rows
            ?.sort((a, b) => moment(a?.startTime).diff(moment(b?.startTime)))
            ?.slice(0, 3)
        );
        setIsGolfLoading(false);
        setTimeout(() => {
          setIsGolfImageLoading(false);
        }, 500);
      }
    } catch (err) {
      setIsCricketLoading(false);
      setIsCricketImageLoading(false);
      setIsRLLoading(false);
      setIsRLImageLoading(false);
      setIsRULoading(false);
      setIsRUImageLoading(false);
      setIsNBALoading(false);
      setIsNBAImageLoading(false);
      setIsAFLLoading(false);
      setIsAFLImageLoading(false);
      setIsARLoading(false);
      setIsARImageLoading(false);
      setIsTennisLoading(false);
      setIsTennisImageLoading(false);
      setIsBaseballLoading(false);
      setIsBaseballImageLoading(false);
      setIsIceHockeyLoading(false);
      setIsIceHockeyImageLoading(false);
      setIsBoxingLoading(false);
      setIsBoxingImageLoading(false);
      setIsMMALoading(false);
      setIsMMAImageLoading(false);
      setIsSoccerLoading(false);
      setIsSoccerImageLoading(false);
      setIsGolfLoading(false);
      setIsGolfImageLoading(false);
    }
  };
  useEffect(() => {
    if (ReduxAdsData) {
      // setAdData(ReduxAdsData)
      fetchAdsData(ReduxAdsData);
    }
  }, [ReduxAdsData]);
  // Navigate On It's Racing page
  // const handleClick = item => {
  //   navigate(`/racing&type=${item[0]?.sportId}`);
  // };

  // Race startDateTime Countdown
  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <Chip
        className={
          hours === 0 && minutes < 5
            ? " allsports_racing_timer m10  bg-orange "
            : "allsports_racing_timer m10 sqare-chip"
        }
        style={{ cursor: "pointer" }}
        size="small"
        label={
          <span>
            {days > 0 ? days + "d" : ""}{" "}
            {days > 0
              ? hours + "h"
              : hours > 0
              ? hours + "h"
              : days === 0 && minutes <= 5
              ? ""
              : ""}{" "}
            {minutes > 0 ? minutes + "m" : "0m"}{" "}
            {hours === 0 && minutes <= 5
              ? seconds > 0
                ? seconds + "s"
                : "0s"
              : ""}
          </span>
        }
      />
    );
  };
  const TeamsportsRenderer = ({ days, hours, minutes, seconds }) => {
    return null;
  };
  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
            placeholder={<Box>Loading..</Box>}
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
            placeholder={<Box>Loading..</Box>}
          />
        ) : (
          <img
            className="team-img"
            src={Brisbane}
            alt="Odds Icon"
            placeholder={<Box>Loading..</Box>}
          />
        );
        return TeamLogo;
      } else {
        return (
          <img
            className="team-img"
            src={Brisbane}
            alt="Odds Icon"
            placeholder={<Box>Loading..</Box>}
          />
        );
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
            placeholder={<Box>Loading..</Box>}
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
            placeholder={<Box>Loading..</Box>}
          />
        ) : (
          <img
            className="team-img"
            src={Melbourne}
            alt="Odds Icon"
            placeholder={<Box>Loading..</Box>}
          />
        );
        return TeamLogo;
      } else {
        return (
          <img
            className="team-img"
            src={Melbourne}
            alt="Odds Icon"
            placeholder={<Box>Loading..</Box>}
          />
        );
      }
    }
  };

  const fetchTournamentlogo = item => {
    if (item?.GolfTournament) {
      let TeamLogo = item?.GolfTournament?.flag?.includes("uploads") ? (
        <img
          src={Config.mediaURL + item?.GolfTournament?.flag}
          className="team-img"
          alt="team Icon"
          placeholder={<Box>Loading..</Box>}
        />
      ) : item?.GolfTournament?.flag ? (
        <img
          src={item?.GolfTournament?.flag}
          className="team-img"
          alt="team Icon"
          placeholder={<Box>Loading..</Box>}
        />
      ) : (
        <img
          className="team-img"
          src={Brisbane}
          alt="Odds Icon"
          placeholder={<Box>Loading..</Box>}
        />
      );
      return TeamLogo;
    } else {
      return (
        <img
          className="team-img"
          src={Brisbane}
          alt="Odds Icon"
          placeholder={<Box>Loading..</Box>}
        />
      );
    }
  };
  // const handleAdsImression = async ids => {
  //   try {
  //     const payload = {
  //       ids: ids
  //     };
  //     const { status, data } = await axiosInstance.post(
  //       `addImpression`,
  //       payload
  //     );
  //     if (status === 200) {
  //     }
  //   } catch (err) {}
  // };

  const fetchAdsData = ReduxAdsData => {
    // try {
    //   const { status, data } = await axiosInstance.get(
    //     `getAllAds?page_id=1,11`
    //   );
    //   if (status === 200) {
    let AdvtData = ReduxAdsData?.filter(item => item?.page_id === 1);
    setAdData(AdvtData);
    // dispatch(fetchAdvtData(AdvtData?.[0]));
    let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
    setPageHeaderData(pageheaderAds);
  };
  const fetchAds = (position, height, placeholder) => {
    // let ad = AdData?.filter(item => {
    //   return item?.position_id === position;
    // });
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  const handleHomeTabChange = id => {
    setHomeTabValue(id);
  };

  const horseListRaceData = horseRaceData?.filter(obj => obj?.raceNumber !== 0);
  const harnessListRaceData = harnessRaceData?.filter(
    obj => obj?.raceNumber !== 0
  );
  const greyhoundListRaceData = greyhoundRaceData?.filter(
    obj => obj?.raceNumber !== 0
  );

  const fetchNews = async () => {
    setNewsLoading(true);
    const allSportId = [
      ...release[Config.release]?.sportId,
      ...release[Config.release]?.raceSportId
    ];
    try {
      const { status, data } = await axiosInstance.get(
        `v2/news/articles?SportId=${
          Config.release == "IN" ? allSportId?.toString() : ""
        }&NewsCategoryId=&limit=10&offset=0`
      );
      if (status === 200) {
        setNewsLoading(false);
        const articalData = data?.result?.raw;
        setNewsData(articalData);
      }
    } catch (error) {
      setNewsLoading(false);
    }
  };
  const fetchNewsTime = newsdate => {
    const now = moment();
    const givenTime = moment(newsdate);
    const timeDiffMinutes = now.diff(givenTime, "minutes");
    const timeDiffHours = now.diff(givenTime, "hours");
    const timeDiffDays = now.diff(givenTime, "days");
    const timeDiffWeeks = now.diff(givenTime, "weeks");
    if (timeDiffMinutes > 60) {
      if (timeDiffMinutes / 60 > 24) {
        if (timeDiffDays > 7) {
          return `${timeDiffWeeks} ${
            timeDiffWeeks == 1 ? "week" : "weeks"
          } ago`;
        } else {
          return `${timeDiffDays} ${timeDiffDays == 1 ? "day" : "days"} ago`;
        }
      } else {
        return `${timeDiffHours} hours ago`;
      }
    } else {
      return `${timeDiffMinutes} minutes ago`;
    }
  };

  const scriptCode = `
    rdt('track', 'ViewContent', {
      "products": [
        {
          "id": "product id 1",
          "name": "product name 1",
          "category": "product category 1"
        },
        // additional products can be added here
      ]
    });
  `;
  const handleNewsNavigte = (item, event) => {
    const url = window?.location?.origin;
    if (event.ctrlKey || event.metaKey) {
      window.open(url + `/news/${item?.NewsCategoryId}/${item?.id}`, "_blank");
    } else {
      navigate(`/news/${item?.NewsCategoryId}/${item?.id}`);
    }
  };

  const fetchNewsSubtitle = data => {
    const parseHTMLContent = body => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(body, "text/html");
      const firstPTag = doc.querySelector("p");
      return firstPTag ? firstPTag.innerHTML : "";
    };
    const decodeHTMLContent = content => {
      return he.decode(content);
    };
    const removeHtmlTags = htmlString => {
      return htmlString.replace(/<[^>]*>/g, "");
    };
    const renderSubtitle = content => {
      return (
        <Typography className="sub-details">
          {screenWidth >= 1023 ? content?.slice(0, 100) : content?.slice(0, 50)}
          <span className="read-more">...read more</span>
        </Typography>
      );
    };
    if (data?.NewsProviderId === 1) {
      const editorBlockData = data?.body?.find(
        item =>
          item?.type === "editor_block" && item?.data?.type === "paragraph"
      );
      const content = parseHTMLContent(editorBlockData?.data?.content);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    } else {
      const content = parseHTMLContent(data?.body);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    }
  };
  return (
    <>
      <ScriptLoader scriptCode={scriptCode} />
      <Box className="content-wrap">
        {/* <AdvertisingBannner src={banner} /> */}
        {fetchPageHeaderAds(
          "218px",
          Config?.release == "IN" ? bannerIndia : banner
        )}
        {/* {fetchAds(1, "218px", banner)} */}
        <Box className="bredcrumn-wrap-home">
          {/* <Breadcrumbs
            separator="/"
            aria-label="breadcrumb"
            className="breadcrumb"
          >
            <Typography>{localesData?.MENU?.HOME}</Typography>
          </Breadcrumbs> */}
          <NavLink to="/">
            <img
              src={logo}
              style={{ maxWidth: " 240.56px" }}
              className="App-logo-main"
              alt="logo"
              effect="blur"
            />
          </NavLink>
          <Typography variant="h1">
            GET THE LATEST SPORTS AND RACING NEWS, INFORMATION, FIXTURES, AND
            SCORES IN AUSTRALIA WITH SMARTB
          </Typography>
          <Typography className="homepage-subheading"></Typography>
        </Box>

        {/* {fetchAds(2, "102px", homeLongBanner)} */}
        <Box className="slider-tab-wrap">
          <Box className="tab-container">
            <Tabs
              value={homeTabValue}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              className="home-tab-details"
              disableRipple
              disableFocusRipple
              id="news-tab"
              ref={tabsRef}
            >
              {homePageTab?.map((item, index) => {
                return (
                  <Box
                    key={index}
                    className={
                      homePageTab?.length == 3
                        ? "w-33 home-tab"
                        : homePageTab?.length == 2
                        ? "w-50 home-tab"
                        : homePageTab?.length == 1
                        ? "w-100 home-tab"
                        : "home-tab"
                    }
                  >
                    <Tab
                      disableRipple
                      disableFocusRipple
                      label={
                        <Box className="tabicon-label-wrap">
                          <span className="hometab-icon">
                            {item?.id == homeTabValue
                              ? item?.activeicon
                              : item?.icon}
                          </span>
                          <span
                            className={
                              item?.id == homeTabValue
                                ? `active-hometab-label hometab-label`
                                : "hometab-label"
                            }
                          >
                            {item?.label}
                          </span>
                        </Box>
                      }
                      value={item?.id}
                      onChange={() =>
                        handleHomeTabChange(item?.id, item?.label)
                      }
                      className={
                        item?.id == homeTabValue ? "active-hometab-button" : ""
                      }
                    />
                  </Box>
                );
              })}
            </Tabs>
          </Box>
          {homeTabValue === 0 ? (
            <FeaturedNews />
          ) : homeTabValue === 1 ? (
            <FeaturedRace />
          ) : (
            <FeaturedSport />
          )}
        </Box>
        {homeTabValue === 0 ? (
          <Box className="home-newstab-details">
            {newsLoading ? (
              <div className="allsport-loader-center ">
                <Loader />
              </div>
            ) : newsData?.length > 0 ? (
              <>
                <Box className="individual-news-cont">
                  <Grid container>
                    {newsData?.map(item => (
                      <Grid
                        item
                        xs={12}
                        className="individual-single-news"
                        key={item?.id}
                        // onClick={e => handleNewsNavigte(item, e)}
                      >
                        <NavLink
                          to={`/news/${item?.NewsCategoryId}/${item?.id}`}
                        >
                          <Box className="news-box">
                            <Box className="news-tag-relative">
                              <Box className="news-img">
                                <img
                                  src={
                                    item?.mainMedia?.gallery
                                      ? item?.mainMedia?.gallery?.url &&
                                        item?.mainMedia?.gallery?.url !== ""
                                        ? item?.mainMedia?.gallery?.url
                                        : DefaultImg
                                      : item?.mainMedia?.[0]?.gallery
                                      ? item?.mainMedia?.[0]?.gallery?.url &&
                                        item?.mainMedia?.[0]?.gallery?.url !==
                                          ""
                                        ? item?.mainMedia?.[0]?.gallery?.url
                                        : DefaultImg
                                      : DefaultImg
                                  }
                                  alt="placeholder"
                                />
                              </Box>
                              <span className="news-tag">
                                {item?.NewsCategory?.initialTitle}
                              </span>
                            </Box>
                            <Box className="individual-news-details">
                              <Typography
                                className="details"
                                dangerouslySetInnerHTML={{
                                  __html: item?.title
                                    ? item?.title
                                    : item?.subTitle
                                }}
                              ></Typography>

                              <Box className="sub-details-wrap">
                                {fetchNewsSubtitle(item)}
                              </Box>

                              <Box className="tag-time-details">
                                {/* <Box className="news-tags">
                                  {screenWidth > 860
                                    ? item?.NewsTagRelations?.slice(0, 3).map(
                                        (data, index) => {
                                          return (
                                            <span
                                              className="individual-news-tag"
                                              key={index}
                                            >
                                              {data?.NewsTag?.title}
                                            </span>
                                          );
                                        }
                                      )
                                    : item?.NewsTagRelations?.slice(0, 1).map(
                                        (data, index) => {
                                          return (
                                            <span
                                              className="individual-news-tag"
                                              key={index}
                                            >
                                              {data?.NewsTag?.title}
                                            </span>
                                          );
                                        }
                                      )}
                                </Box> */}
                                <Typography className="stories-time">
                                  <span className="watch">
                                    <Watch />
                                  </span>
                                  {fetchNewsTime(item?.rapidCreatedAt)}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </NavLink>
                      </Grid>
                    ))}
                  </Grid>
                  <Box
                    className="View-more-wrap"
                    onClick={() => navigate("news/0")}
                  >
                    View More
                    <span>
                      <RightArrow />
                    </span>
                  </Box>
                </Box>
                <Box className="news-Pagination">
                  {/* {params?.categoryId != 100 ? ( */}
                  {/* <Pagination
                  // hideNextButton
                  // hidePrevButton
                  disabled={categoryCount / rowPerPage > 1 ? false : true}
                  page={currentPage}
                  onChange={(e, value) => handlePaginationClick(e, value)}
                  count={pageNumbers[pageNumbers?.length - 1]}
                  siblingCount={2}
                  boundaryCount={1}
                  // count={10}
                  size="small"
                /> */}
                  {/* ) : (
                <></>
              )} */}
                </Box>
              </>
            ) : (
              <>
                <Box style={{ textAlign: "center" }}>
                  {" "}
                  <NoDataComp />{" "}
                </Box>
              </>
            )}
          </Box>
        ) : homeTabValue === 1 ? (
          <Box className="sport-wrap">
            <Box className="racing-wrap ">
              <Box className="sport-grid">
                {/* Horse Racing Events */}
                <Box className="sport-inner">
                  <Box className="racing-list sport-shadow" ref={elementRef}>
                    <Box className="sport-title">
                      <span className="sport-title-name">
                        <img
                          src={RaceHorses}
                          width={"27px"}
                          height={"27px"}
                          alt="horserace"
                        />
                        {localesData?.next_to_jump} Horses
                      </span>
                      <span className="see-all">
                        <Link to={`/racing?type=1`}>
                          {localesData?.sport?.seeall}
                        </Link>
                      </span>
                    </Box>
                    {isThreeRaceLoading && (
                      <div className="allsport-loader-center">
                        <Loader />
                      </div>
                    )}
                    {!isThreeRaceLoading &&
                      (horseListRaceData?.length > 0 ? (
                        horseListRaceData?.map((obj, i) => (
                          <React.Fragment key={obj?.id}>
                            <Box
                              className="racing-content"
                              onClick={() => {
                                navigate(
                                  // Redirect to Runner Details
                                  Routes.RunnerDetails(
                                    "horse",
                                    obj?.sportId,
                                    obj?.id,
                                    obj?.event?.trackId,
                                    obj?.eventId,
                                    obj?.startTimeDate,
                                    obj?.event?.track?.countryId === 13 ||
                                      obj?.event?.track?.countryId === 157
                                      ? false
                                      : true
                                  )
                                );
                              }}
                            >
                              <img
                                src={
                                  obj?.event?.track?.countryObj?.country_flag?.includes(
                                    "uploads"
                                  )
                                    ? Config.mediaURL +
                                      obj?.event?.track?.countryObj
                                        ?.country_flag
                                    : obj?.event?.track?.countryObj
                                        ?.country_flag
                                }
                                width={"37px"}
                                height={"22px"}
                                alt="horserace"
                              />
                              <span className="event-name">
                                {`R${obj?.raceNumber}`} {obj?.event?.eventName}
                              </span>
                              {screenWidth > 799 ? (
                                <span className="country-name">
                                  (
                                  {obj?.event?.track?.countryObj
                                    ? obj?.event?.track?.countryObj?.country
                                    : ""}
                                  )
                                </span>
                              ) : (
                                <span className="country-name">
                                  (
                                  {obj?.event?.track?.countryObj
                                    ? obj?.event?.track?.countryObj?.countryCode
                                    : ""}
                                  )
                                </span>
                              )}
                              <Countdown
                                date={moment.utc(obj?.startTimeDate).toDate()}
                                renderer={renderer}
                                onComplete={() =>
                                  setTimeout(
                                    () => fetchThreeRacing(false, 1),
                                    2000
                                  )
                                }
                                key={moment.utc(obj?.startTimeDate).format()}
                              />
                            </Box>
                          </React.Fragment>
                        ))
                      ) : (
                        <div className="allsport-loader-center">
                          <p>{localesData?.sport?.no_race}</p>
                        </div>
                      ))}
                  </Box>
                </Box>
                {/* Greyhounds Racing Events */}
                <Box className="sport-inner">
                  <Box className="racing-list sport-shadow">
                    <Box className="sport-title">
                      <span className="sport-title-name">
                        <img
                          src={RaceGreyhounds}
                          width={"27px"}
                          height={"27px"}
                          alt="greyhoundrace"
                          effect="blur"
                        />
                        {localesData?.next_to_jump} Greyhounds
                      </span>
                      <span className="see-all">
                        <Link to={`/racing?type=3`}>
                          {localesData?.sport?.seeall}
                        </Link>
                      </span>
                    </Box>
                    {isThreeRaceLoading && (
                      <div className="allsport-loader-center">
                        <Loader />
                      </div>
                    )}
                    {!isThreeRaceLoading &&
                      (greyhoundListRaceData?.length > 0 ? (
                        greyhoundListRaceData?.map((obj, i) => (
                          <React.Fragment key={obj?.id}>
                            <Box
                              className="racing-content"
                              onClick={() => {
                                navigate(
                                  // Redirect to Runner Details
                                  Routes.RunnerDetails(
                                    "greyhounds",
                                    obj?.sportId,
                                    obj?.id,
                                    obj?.event?.trackId,
                                    obj?.eventId,
                                    obj?.startTimeDate,
                                    obj?.event?.track?.countryId === 13 ||
                                      obj?.event?.track?.countryId === 157
                                      ? false
                                      : true
                                  )
                                );
                              }}
                            >
                              <img
                                src={
                                  obj?.event?.track?.countryObj?.country_flag?.includes(
                                    "uploads"
                                  )
                                    ? Config.mediaURL +
                                      obj?.event?.track?.countryObj
                                        ?.country_flag
                                    : obj?.event?.track?.countryObj
                                        ?.country_flag
                                }
                                width={"37px"}
                                height={"22px"}
                                alt="greyhoundrace"
                              />
                              <span className="event-name">
                                {`R${obj?.raceNumber}`} {obj?.event?.eventName}
                              </span>
                              {screenWidth > 799 ? (
                                <span className="country-name">
                                  (
                                  {obj?.event?.track?.countryObj
                                    ? obj?.event?.track?.countryObj?.country
                                    : ""}
                                  )
                                </span>
                              ) : (
                                <span className="country-name">
                                  (
                                  {obj?.event?.track?.countryObj
                                    ? obj?.event?.track?.countryObj?.countryCode
                                    : ""}
                                  )
                                </span>
                              )}
                              <Countdown
                                date={moment.utc(obj?.startTimeDate).toDate()}
                                renderer={renderer}
                                onComplete={() =>
                                  setTimeout(
                                    () => fetchThreeRacing(false, 1),
                                    2000
                                  )
                                }
                                key={moment.utc(obj?.startTimeDate).format()}
                              />
                            </Box>
                          </React.Fragment>
                        ))
                      ) : (
                        <div className="allsport-loader-center">
                          <p>{localesData?.sport?.no_race}</p>
                        </div>
                      ))}
                  </Box>
                </Box>

                {/* Harness Racing Events */}
                <Box className="sport-inner">
                  <Box className="racing-list sport-shadow">
                    <Box className="sport-title">
                      <span className="sport-title-name">
                        <img
                          src={RaceHarness}
                          width={"27px"}
                          height={"27px"}
                          alt="harnessrace"
                        />
                        {localesData?.next_to_jump} Harness
                      </span>
                      <span className="see-all">
                        <Link to={`/racing?type=2`}>
                          {localesData?.sport?.seeall}
                        </Link>
                      </span>
                    </Box>
                    {isThreeRaceLoading && (
                      <div className="allsport-loader-center">
                        <Loader />
                      </div>
                    )}
                    {!isThreeRaceLoading &&
                      (harnessListRaceData?.length > 0 ? (
                        harnessListRaceData?.map((obj, i) => (
                          <React.Fragment key={obj?.id}>
                            <Box
                              key={obj?.id}
                              className="racing-content"
                              onClick={() => {
                                navigate(
                                  // Redirect to Runner Details
                                  Routes.RunnerDetails(
                                    "harness",
                                    obj?.sportId,
                                    obj?.id,
                                    obj?.event?.trackId,
                                    obj?.eventId,
                                    obj?.startTimeDate,
                                    obj?.event?.track?.countryId === 13 ||
                                      obj?.event?.track?.countryId === 157
                                      ? false
                                      : true
                                  )
                                );
                              }}
                            >
                              <img
                                src={
                                  obj?.event?.track?.countryObj?.country_flag?.includes(
                                    "uploads"
                                  )
                                    ? Config.mediaURL +
                                      obj?.event?.track?.countryObj
                                        ?.country_flag
                                    : obj?.event?.track?.countryObj
                                        ?.country_flag
                                }
                                width={"37px"}
                                height={"22px"}
                                alt="flag"
                              />
                              <span className="event-name">
                                {`R${obj?.raceNumber}`} {obj?.event?.eventName}
                              </span>
                              {screenWidth > 799 ? (
                                <span className="country-name">
                                  (
                                  {obj?.event?.track?.countryObj
                                    ? obj?.event?.track?.countryObj?.country
                                    : ""}
                                  )
                                </span>
                              ) : (
                                <span className="country-name">
                                  (
                                  {obj?.event?.track?.countryObj
                                    ? obj?.event?.track?.countryObj?.countryCode
                                    : ""}
                                  )
                                </span>
                              )}
                              <Countdown
                                date={moment.utc(obj?.startTimeDate).toDate()}
                                renderer={renderer}
                                onComplete={() =>
                                  setTimeout(
                                    () => fetchThreeRacing(false, 1),
                                    2000
                                  )
                                }
                                key={moment.utc(obj?.startTimeDate).format()}
                              />
                            </Box>
                          </React.Fragment>
                        ))
                      ) : (
                        <div className="allsport-loader-center">
                          <p>{localesData?.sport?.no_race}</p>
                        </div>
                      ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className="sport-wrap">
            <Box className="racing-wrap ">
              <Box className="sport-grid home-page-sport-grid">
                {!isAFLLoading &&
                  (AFLData?.length > 0 ? (
                    <Box className="sport-inner teamsport-inner">
                      <Box
                        className="racing-list sport-shadow"
                        ref={elementRef}
                      >
                        <Box className="sport-title">
                          <span className="sport-title-name">
                            <span className="sport-icon">
                              <AmericanFootball />
                            </span>
                            American Football
                          </span>
                          <span className="see-all">
                            <Link
                              to={`/teamsports/americanfootball/odds/0/false`}
                            >
                              {localesData?.sport?.seeall}
                            </Link>
                          </span>
                        </Box>
                        {isAFLLoading && (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        )}
                        <Box className="eventlist-wrap">
                          {AFLData?.map((item, i) => (
                            <React.Fragment key={item?.id}>
                              <Box
                                className="teamsport-content"
                                onClick={() => {
                                  navigate(
                                    release[Config.release]?.oddsCheck
                                      ? `/teamsports/americanfootball/odds/${item?.id}/true`
                                      : `/teamsports/americanfootball/odds/0/false`
                                  );
                                }}
                              >
                                <Box className="event-wrap">
                                  <Box className="teams-details">
                                    <Box className="team-wrap">
                                      {isAFLImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "hometeam")}
                                        </span>
                                      )}
                                    </Box>
                                    <Box className="event-details-wrap">
                                      <span className="event-name">
                                        {item?.AFLTournament?.name}{" "}
                                      </span>
                                      <Box className="event-teams">
                                        {" "}
                                        {item?.eventName}
                                      </Box>
                                      <Box className="date-time-wrap">
                                        <Box className="event-date">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("DD/MM/YYYY")}
                                        </Box>
                                        <Box className="event-time">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("hh:mm a")}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="team-wrap">
                                      {isAFLImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "awayteam")}
                                        </span>
                                      )}
                                    </Box>
                                  </Box>

                                  <Countdown
                                    date={moment.utc(item?.startTime).toDate()}
                                    renderer={TeamsportsRenderer}
                                    onComplete={() =>
                                      setTimeout(
                                        () => fetchUpcomingAFLEvent(),
                                        2000
                                      )
                                    }
                                    key={moment.utc(item?.startTime).format()}
                                  />
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  ))}

                {!isARLoading &&
                  (ARData?.length > 0 ? (
                    <Box className="sport-inner teamsport-inner">
                      <Box
                        className="racing-list sport-shadow"
                        ref={elementRef}
                      >
                        <Box className="sport-title">
                          <span className="sport-title-name">
                            <span className="sport-icon">
                              <AustralianRules />
                            </span>
                            Australian Rules
                          </span>
                          <span className="see-all">
                            <Link
                              to={`/teamsports/australianrules/odds/0/false`}
                            >
                              {localesData?.sport?.seeall}
                            </Link>
                          </span>
                        </Box>
                        {isARLoading && (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        )}
                        <Box className="eventlist-wrap">
                          {ARData?.map((item, i) => (
                            <React.Fragment key={item?.id}>
                              <Box
                                className="teamsport-content"
                                onClick={() => {
                                  navigate(
                                    release[Config.release]?.oddsCheck
                                      ? `/teamsports/australianrules/odds/${item?.id}/true`
                                      : `/teamsports/australianrules/odds/0/false`
                                  );
                                }}
                              >
                                <Box className="event-wrap">
                                  <Box className="teams-details">
                                    <Box className="team-wrap">
                                      {isARImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "hometeam")}
                                        </span>
                                      )}
                                    </Box>
                                    <Box className="event-details-wrap">
                                      <span className="event-name">
                                        {item?.ARTournament?.name}{" "}
                                      </span>
                                      <Box className="event-teams">
                                        {" "}
                                        {item?.eventName}
                                      </Box>
                                      <Box className="date-time-wrap">
                                        <Box className="event-date">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("DD/MM/YYYY")}
                                        </Box>
                                        <Box className="event-time">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("hh:mm a")}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="team-wrap">
                                      {isARImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "awayteam")}
                                        </span>
                                      )}
                                    </Box>
                                  </Box>

                                  <Countdown
                                    date={moment.utc(item?.startTime).toDate()}
                                    renderer={TeamsportsRenderer}
                                    onComplete={() =>
                                      setTimeout(
                                        () => fetchUpcomingAREvent(),
                                        2000
                                      )
                                    }
                                    key={moment.utc(item?.startTime).format()}
                                  />
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  ))}

                {!isBaseballLoading &&
                  (BaseballData?.length > 0 ? (
                    <Box className="sport-inner teamsport-inner">
                      <Box
                        className="racing-list sport-shadow"
                        ref={elementRef}
                      >
                        <Box className="sport-title">
                          <span className="sport-title-name">
                            <span className="sport-icon">
                              <Baseball />
                            </span>
                            Baseball
                          </span>
                          <span className="see-all">
                            <Link to={`/teamsports/baseball/odds/0/false`}>
                              {localesData?.sport?.seeall}
                            </Link>
                          </span>
                        </Box>
                        {isBaseballLoading && (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        )}
                        <Box className="eventlist-wrap">
                          {BaseballData?.map((item, i) => (
                            <React.Fragment key={item?.id}>
                              <Box
                                className="teamsport-content"
                                onClick={() => {
                                  navigate(
                                    release[Config.release]?.oddsCheck
                                      ? `/teamsports/baseball/odds/${item?.id}/true`
                                      : `/teamsports/baseball/odds/0/false`
                                  );
                                }}
                              >
                                <Box className="event-wrap">
                                  <Box className="teams-details">
                                    <Box className="team-wrap">
                                      {isBaseballImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "hometeam")}
                                        </span>
                                      )}
                                    </Box>
                                    <Box className="event-details-wrap">
                                      <span className="event-name">
                                        {item?.BaseballTournament?.name}{" "}
                                      </span>
                                      <Box className="event-teams">
                                        {" "}
                                        {item?.eventName}
                                      </Box>
                                      <Box className="date-time-wrap">
                                        <Box className="event-date">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("DD/MM/YYYY")}
                                        </Box>
                                        <Box className="event-time">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("hh:mm a")}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="team-wrap">
                                      {isBaseballImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "awayteam")}
                                        </span>
                                      )}
                                    </Box>
                                  </Box>

                                  <Countdown
                                    date={moment.utc(item?.startTime).toDate()}
                                    renderer={TeamsportsRenderer}
                                    onComplete={() =>
                                      setTimeout(
                                        () => fetchUpcomingBaseballEvent(),
                                        2000
                                      )
                                    }
                                    key={moment.utc(item?.startTime).format()}
                                  />
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  ))}

                {!isNBALoading &&
                  (NBAData?.length > 0 ? (
                    <Box className="sport-inner teamsport-inner">
                      <Box
                        className="racing-list sport-shadow"
                        ref={elementRef}
                      >
                        <Box className="sport-title">
                          <span className="sport-title-name">
                            <span className="sport-icon">
                              <Basketball />
                            </span>
                            Basketball
                          </span>
                          <span className="see-all">
                            <Link to={`/teamsports/basketball/odds/0/false`}>
                              {localesData?.sport?.seeall}
                            </Link>
                          </span>
                        </Box>
                        {isNBALoading && (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        )}
                        <Box className="eventlist-wrap">
                          {NBAData?.map((item, i) => (
                            <React.Fragment key={item?.id}>
                              <Box
                                className="teamsport-content"
                                onClick={() => {
                                  navigate(
                                    release[Config.release]?.oddsCheck
                                      ? `/teamsports/basketball/odds/${item?.id}/true`
                                      : `/teamsports/basketball/odds/0/false`
                                  );
                                }}
                              >
                                <Box className="event-wrap">
                                  <Box className="teams-details">
                                    <Box className="team-wrap">
                                      {isNBAImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "hometeam")}
                                        </span>
                                      )}
                                    </Box>
                                    <Box className="event-details-wrap">
                                      <span className="event-name">
                                        {item?.NBATournament?.name}{" "}
                                      </span>
                                      <Box className="event-teams">
                                        {" "}
                                        {item?.eventName}
                                      </Box>
                                      <Box className="date-time-wrap">
                                        <Box className="event-date">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("DD/MM/YYYY")}
                                        </Box>
                                        <Box className="event-time">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("hh:mm a")}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="team-wrap">
                                      {isNBAImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "awayteam")}
                                        </span>
                                      )}
                                    </Box>
                                  </Box>

                                  <Countdown
                                    date={moment.utc(item?.startTime).toDate()}
                                    renderer={TeamsportsRenderer}
                                    onComplete={() =>
                                      setTimeout(
                                        () => fetchUpcomingNBAEvent(),
                                        2000
                                      )
                                    }
                                    key={moment.utc(item?.startTime).format()}
                                  />
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  ))}

                {!isBoxingLoading &&
                  (BoxingData?.length > 0 ? (
                    <Box className="sport-inner teamsport-inner">
                      <Box
                        className="racing-list sport-shadow"
                        ref={elementRef}
                      >
                        <Box className="sport-title">
                          <span className="sport-title-name">
                            <span className="sport-icon">
                              <Boxing />
                            </span>
                            Boxing
                          </span>
                          <span className="see-all">
                            <Link to={`/teamsports/boxing/odds/0/false`}>
                              {localesData?.sport?.seeall}
                            </Link>
                          </span>
                        </Box>
                        {isBoxingLoading && (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        )}
                        <Box className="eventlist-wrap">
                          {BoxingData?.map((item, i) => (
                            <React.Fragment key={item?.id}>
                              <Box
                                className="teamsport-content"
                                onClick={() => {
                                  navigate(
                                    release[Config.release]?.oddsCheck
                                      ? `/teamsports/boxing/odds/${item?.id}/true`
                                      : `/teamsports/boxing/odds/0/false`
                                  );
                                }}
                              >
                                <Box className="event-wrap">
                                  <Box className="teams-details">
                                    <Box className="team-wrap">
                                      {isBoxingImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "hometeam")}
                                        </span>
                                      )}
                                    </Box>
                                    <Box className="event-details-wrap">
                                      <span className="event-name">
                                        {item?.BoxingTournament?.name}{" "}
                                      </span>
                                      <Box className="event-teams">
                                        {" "}
                                        {item?.eventName}
                                      </Box>
                                      <Box className="date-time-wrap">
                                        <Box className="event-date">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("DD/MM/YYYY")}
                                        </Box>
                                        <Box className="event-time">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("hh:mm a")}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="team-wrap">
                                      {isBoxingImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "awayteam")}
                                        </span>
                                      )}
                                    </Box>
                                  </Box>

                                  <Countdown
                                    date={moment.utc(item?.startTime).toDate()}
                                    renderer={TeamsportsRenderer}
                                    onComplete={() =>
                                      setTimeout(
                                        () => fetchUpcomingBoxingEvent(),
                                        2000
                                      )
                                    }
                                    key={moment.utc(item?.startTime).format()}
                                  />
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  ))}

                {!isCricketLoading &&
                  (cricketData?.length > 0 ? (
                    <Box className="sport-inner teamsport-inner">
                      <Box
                        className="racing-list sport-shadow"
                        ref={elementRef}
                      >
                        <Box className="sport-title">
                          <span className="sport-title-name">
                            <span className="sport-icon">
                              <Cricket />
                            </span>
                            Cricket
                          </span>
                          <span className="see-all">
                            <Link to={`/teamsports/cricket/odds/0/false`}>
                              {localesData?.sport?.seeall}
                            </Link>
                          </span>
                        </Box>
                        {isCricketLoading && (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        )}
                        <Box className="eventlist-wrap">
                          {cricketData?.map((item, i) => (
                            <React.Fragment key={item?.id}>
                              <Box
                                className="teamsport-content"
                                onClick={() => {
                                  navigate(
                                    release[Config.release]?.oddsCheck
                                      ? `/teamsports/cricket/odds/${item?.id}/true`
                                      : `/teamsports/cricket/odds/0/false`
                                  );
                                }}
                              >
                                <Box className="event-wrap">
                                  <Box className="teams-details">
                                    <Box className="team-wrap">
                                      {isCricketImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "hometeam")}
                                        </span>
                                      )}
                                    </Box>
                                    <Box className="event-details-wrap">
                                      <span className="event-name">
                                        {item?.CricketTournament?.name}{" "}
                                      </span>
                                      <Box className="event-teams">
                                        {" "}
                                        {item?.eventName}
                                      </Box>
                                      <Box className="date-time-wrap">
                                        <Box className="event-date">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("DD/MM/YYYY")}
                                        </Box>
                                        <Box className="event-time">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("hh:mm a")}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="team-wrap">
                                      {isCricketImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "awayteam")}
                                        </span>
                                      )}
                                    </Box>
                                  </Box>

                                  <Countdown
                                    date={moment.utc(item?.startTime).toDate()}
                                    renderer={TeamsportsRenderer}
                                    onComplete={() =>
                                      setTimeout(
                                        () => fetchUpcomingCricketEvent(),
                                        2000
                                      )
                                    }
                                    key={moment.utc(item?.startTime).format()}
                                  />
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  ))}

                {!isGolfLoading &&
                  (golfData?.length > 0 ? (
                    <Box className="sport-inner teamsport-inner">
                      <Box
                        className="racing-list sport-shadow"
                        ref={elementRef}
                      >
                        <Box className="sport-title">
                          <span className="sport-title-name">
                            <span className="sport-icon">
                              <Golf />
                            </span>
                            Golf
                          </span>
                          <span className="see-all">
                            <Link to={`/teamsports/golf/outrights/0/false`}>
                              {localesData?.sport?.seeall}
                            </Link>
                          </span>
                        </Box>
                        {isGolfLoading && (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        )}
                        <Box className="eventlist-wrap">
                          {golfData?.map((item, i) => (
                            <React.Fragment>
                              <Box
                                className="teamsport-content"
                                onClick={() => {
                                  navigate(
                                    release[Config.release]?.oddsCheck
                                      ? `/teamsports/golf/outrights/${item?.id}/true`
                                      : `/teamsports/golf/odds/0/false`
                                  );
                                }}
                              >
                                <Box className="event-wrap">
                                  <Box className="teams-details tournament-details">
                                    <Box className="team-wrap">
                                      {isGolfImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTournamentlogo(item)}
                                        </span>
                                      )}
                                    </Box>
                                    <Box className="event-details-wrap golf-event-details-wrap">
                                      <span className="event-name">
                                        {item?.GolfTournament?.name}{" "}
                                      </span>
                                      <Box className="event-teams">
                                        {" "}
                                        {item?.eventName}
                                      </Box>
                                      <Box className="date-time-wrap">
                                        <Box className="event-date">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("DD/MM/YYYY")}
                                        </Box>
                                        <Box className="event-time">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("hh:mm a")}
                                        </Box>
                                      </Box>
                                    </Box>
                                    {/* <Box className="team-wrap">
                                {isGolfImageLoading ? (
                                  <div className="allsport-loader-center">
                                    <Loader />
                                  </div>
                                ) : (
                                  <span className="team-img-wrap">
                                    {/* {fetchTeamlogo(item, "awayteam")}
                                  </span>
                                )}
                              </Box> */}
                                  </Box>

                                  <Countdown
                                    date={moment.utc(item?.startTime).toDate()}
                                    renderer={TeamsportsRenderer}
                                    onComplete={() =>
                                      setTimeout(
                                        () => fetchUpcomingGolfEvent(),
                                        2000
                                      )
                                    }
                                    key={moment.utc(item?.startTime).format()}
                                  />
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  ))}

                {!isIceHockeyLoading &&
                  (IceHockeyData?.length > 0 ? (
                    <Box className="sport-inner teamsport-inner">
                      <Box
                        className="racing-list sport-shadow"
                        ref={elementRef}
                      >
                        <Box className="sport-title">
                          <span className="sport-title-name">
                            <span className="sport-icon">
                              <IceHockey />
                            </span>
                            Ice Hockey
                          </span>
                          <span className="see-all">
                            <Link to={`/teamsports/icehockey/odds/0/false`}>
                              {localesData?.sport?.seeall}
                            </Link>
                          </span>
                        </Box>
                        {isIceHockeyLoading && (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        )}
                        <Box className="eventlist-wrap">
                          {IceHockeyData?.map((item, i) => (
                            <React.Fragment key={item?.id}>
                              <Box
                                className="teamsport-content"
                                onClick={() => {
                                  navigate(
                                    release[Config.release]?.oddsCheck
                                      ? `/teamsports/icehockey/odds/${item?.id}/true`
                                      : `/teamsports/icehockey/odds/0/false`
                                  );
                                }}
                              >
                                <Box className="event-wrap">
                                  <Box className="teams-details">
                                    <Box className="team-wrap">
                                      {isIceHockeyImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "hometeam")}
                                        </span>
                                      )}
                                    </Box>
                                    <Box className="event-details-wrap">
                                      <span className="event-name">
                                        {item?.IceHockeyTournament?.name}{" "}
                                      </span>
                                      <Box className="event-teams">
                                        {" "}
                                        {item?.eventName}
                                      </Box>
                                      <Box className="date-time-wrap">
                                        <Box className="event-date">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("DD/MM/YYYY")}
                                        </Box>
                                        <Box className="event-time">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("hh:mm a")}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="team-wrap">
                                      {isIceHockeyImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "awayteam")}
                                        </span>
                                      )}
                                    </Box>
                                  </Box>

                                  <Countdown
                                    date={moment.utc(item?.startTime).toDate()}
                                    renderer={TeamsportsRenderer}
                                    onComplete={() =>
                                      setTimeout(
                                        () => fetchUpcomingIceHockeyEvent(),
                                        2000
                                      )
                                    }
                                    key={moment.utc(item?.startTime).format()}
                                  />
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  ))}

                {!isMMALoading &&
                  (MMAData?.length > 0 ? (
                    <Box className="sport-inner teamsport-inner">
                      <Box
                        className="racing-list sport-shadow"
                        ref={elementRef}
                      >
                        <Box className="sport-title">
                          <span className="sport-title-name">
                            <span className="sport-icon">
                              <MixedMartialArts />
                            </span>
                            Mixed Martial Arts
                          </span>
                          <span className="see-all">
                            <Link to={`/teamsports/mma/odds/0/false`}>
                              {localesData?.sport?.seeall}
                            </Link>
                          </span>
                        </Box>
                        {isMMALoading && (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        )}
                        <Box className="eventlist-wrap">
                          {MMAData?.map((item, i) => (
                            <React.Fragment key={item?.id}>
                              <Box
                                className="teamsport-content"
                                onClick={() => {
                                  navigate(
                                    release[Config.release]?.oddsCheck
                                      ? `/teamsports/mma/odds/${item?.id}/true`
                                      : `/teamsports/mma/odds/0/false`
                                  );
                                }}
                              >
                                <Box className="event-wrap">
                                  <Box className="teams-details">
                                    <Box className="team-wrap">
                                      {isMMAImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "hometeam")}
                                        </span>
                                      )}
                                    </Box>
                                    <Box className="event-details-wrap">
                                      <span className="event-name">
                                        {item?.MMATournament?.name}{" "}
                                      </span>
                                      <Box className="event-teams">
                                        {" "}
                                        {item?.eventName}
                                      </Box>
                                      <Box className="date-time-wrap">
                                        <Box className="event-date">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("DD/MM/YYYY")}
                                        </Box>
                                        <Box className="event-time">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("hh:mm a")}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="team-wrap">
                                      {isMMAImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "awayteam")}
                                        </span>
                                      )}
                                    </Box>
                                  </Box>

                                  <Countdown
                                    date={moment.utc(item?.startTime).toDate()}
                                    renderer={TeamsportsRenderer}
                                    onComplete={() =>
                                      setTimeout(
                                        () => fetchUpcomingMMAEvent(),
                                        2000
                                      )
                                    }
                                    key={moment.utc(item?.startTime).format()}
                                  />
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  ))}

                {!isRLLoading &&
                  (RLData?.length > 0 ? (
                    <Box className="sport-inner teamsport-inner">
                      <Box
                        className="racing-list sport-shadow"
                        ref={elementRef}
                      >
                        <Box className="sport-title">
                          <span className="sport-title-name">
                            <span className="sport-icon">
                              <RugbyLeague />
                            </span>
                            Rugby League
                          </span>
                          <span className="see-all">
                            <Link to={`/teamsports/rugbyleague/odds/0/false`}>
                              {localesData?.sport?.seeall}
                            </Link>
                          </span>
                        </Box>
                        {isRLLoading && (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        )}
                        <Box className="eventlist-wrap">
                          {RLData?.map((item, i) => (
                            <React.Fragment key={item?.id}>
                              <Box
                                className="teamsport-content"
                                onClick={() => {
                                  navigate(
                                    release[Config.release]?.oddsCheck
                                      ? `/teamsports/rugbyleague/odds/${item?.id}/true`
                                      : `/teamsports/rugbyleague/odds/0/false`
                                  );
                                }}
                              >
                                <Box className="event-wrap">
                                  <Box className="teams-details">
                                    <Box className="team-wrap">
                                      {isRLImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "hometeam")}
                                        </span>
                                      )}
                                    </Box>
                                    <Box className="event-details-wrap">
                                      <span className="event-name">
                                        {item?.RLTournament?.name}{" "}
                                      </span>
                                      <Box className="event-teams">
                                        {" "}
                                        {item?.eventName}
                                      </Box>
                                      <Box className="date-time-wrap">
                                        <Box className="event-date">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("DD/MM/YYYY")}
                                        </Box>
                                        <Box className="event-time">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("hh:mm a")}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="team-wrap">
                                      {isRLImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "awayteam")}
                                        </span>
                                      )}
                                    </Box>
                                  </Box>

                                  <Countdown
                                    date={moment.utc(item?.startTime).toDate()}
                                    renderer={TeamsportsRenderer}
                                    onComplete={() =>
                                      setTimeout(
                                        () => fetchUpcomingRLEvent(),
                                        2000
                                      )
                                    }
                                    key={moment.utc(item?.startTime).format()}
                                  />
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  ))}

                {!isRULoading &&
                  (RUData?.length > 0 ? (
                    <Box className="sport-inner teamsport-inner">
                      <Box
                        className="racing-list sport-shadow"
                        ref={elementRef}
                      >
                        <Box className="sport-title">
                          <span className="sport-title-name">
                            <span className="sport-icon">
                              <RugbyUnion />
                            </span>
                            Rugby Union
                          </span>
                          <span className="see-all">
                            <Link to={`/teamsports/rugbyunion/odds/0/false`}>
                              {localesData?.sport?.seeall}
                            </Link>
                          </span>
                        </Box>
                        {isRULoading && (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        )}
                        <Box className="eventlist-wrap">
                          {RUData?.map((item, i) => (
                            <React.Fragment key={item?.id}>
                              <Box
                                className="teamsport-content"
                                onClick={() => {
                                  navigate(
                                    release[Config.release]?.oddsCheck
                                      ? `/teamsports/rugbyunion/odds/${item?.id}/true`
                                      : `/teamsports/rugbyunion/odds/0/false`
                                  );
                                }}
                              >
                                <Box className="event-wrap">
                                  <Box className="teams-details">
                                    <Box className="team-wrap">
                                      {isRLImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "hometeam")}
                                        </span>
                                      )}
                                    </Box>
                                    <Box className="event-details-wrap">
                                      <span className="event-name">
                                        {item?.RLTournament?.name}{" "}
                                      </span>
                                      <Box className="event-teams">
                                        {" "}
                                        {item?.eventName}
                                      </Box>
                                      <Box className="date-time-wrap">
                                        <Box className="event-date">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("DD/MM/YYYY")}
                                        </Box>
                                        <Box className="event-time">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("hh:mm a")}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="team-wrap">
                                      {isRLImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "awayteam")}
                                        </span>
                                      )}
                                    </Box>
                                  </Box>

                                  <Countdown
                                    date={moment.utc(item?.startTime).toDate()}
                                    renderer={TeamsportsRenderer}
                                    onComplete={() =>
                                      setTimeout(
                                        () => fetchUpcomingRUEvent(),
                                        2000
                                      )
                                    }
                                    key={moment.utc(item?.startTime).format()}
                                  />
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  ))}

                {!isSoccerLoading &&
                  (SoccerData?.length > 0 ? (
                    <Box className="sport-inner teamsport-inner">
                      <Box
                        className="racing-list sport-shadow"
                        ref={elementRef}
                      >
                        <Box className="sport-title">
                          <span className="sport-title-name">
                            <span className="sport-icon">
                              <Soccer />
                            </span>
                            Soccer
                          </span>
                          <span className="see-all">
                            <Link to={`/teamsports/soccer/odds/0/false`}>
                              {localesData?.sport?.seeall}
                            </Link>
                          </span>
                        </Box>
                        {isSoccerLoading && (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        )}
                        <Box className="eventlist-wrap">
                          {SoccerData?.map((item, i) => (
                            <React.Fragment key={item?.id}>
                              <Box
                                className="teamsport-content"
                                onClick={() => {
                                  navigate(
                                    release[Config.release]?.oddsCheck
                                      ? `/teamsports/soccer/odds/${item?.id}/true`
                                      : `/teamsports/soccer/odds/0/false`
                                  );
                                }}
                              >
                                <Box className="event-wrap">
                                  <Box className="teams-details">
                                    <Box className="team-wrap">
                                      {isSoccerImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "hometeam")}
                                        </span>
                                      )}
                                    </Box>
                                    <Box className="event-details-wrap">
                                      <span className="event-name">
                                        {item?.SoccerTournament?.name}{" "}
                                      </span>
                                      <Box className="event-teams">
                                        {" "}
                                        {item?.eventName}
                                      </Box>
                                      <Box className="date-time-wrap">
                                        <Box className="event-date">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("DD/MM/YYYY")}
                                        </Box>
                                        <Box className="event-time">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("hh:mm a")}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="team-wrap">
                                      {isSoccerImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "awayteam")}
                                        </span>
                                      )}
                                    </Box>
                                  </Box>

                                  <Countdown
                                    date={moment.utc(item?.startTime).toDate()}
                                    renderer={TeamsportsRenderer}
                                    onComplete={() =>
                                      setTimeout(
                                        () => fetchUpcomingSoccerEvent(),
                                        2000
                                      )
                                    }
                                    key={moment.utc(item?.startTime).format()}
                                  />
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  ))}

                {!isTennisLoading &&
                  (TennisData?.length > 0 ? (
                    <Box className="sport-inner teamsport-inner">
                      <Box
                        className="racing-list sport-shadow"
                        ref={elementRef}
                      >
                        <Box className="sport-title">
                          <span className="sport-title-name">
                            <span className="sport-icon">
                              <Tennis />
                            </span>
                            Tennis
                          </span>
                          <span className="see-all">
                            <Link to={`/teamsports/tennis/odds/0/false`}>
                              {localesData?.sport?.seeall}
                            </Link>
                          </span>
                        </Box>
                        {isTennisLoading && (
                          <div className="allsport-loader-center">
                            <Loader />
                          </div>
                        )}
                        <Box className="eventlist-wrap">
                          {TennisData?.map((item, i) => (
                            <React.Fragment key={item?.id}>
                              <Box
                                className="teamsport-content"
                                onClick={() => {
                                  navigate(
                                    release[Config.release]?.oddsCheck
                                      ? `/teamsports/tennis/odds/${item?.id}/true`
                                      : `/teamsports/tennis/odds/0/false`
                                  );
                                }}
                              >
                                <Box className="event-wrap">
                                  <Box className="teams-details">
                                    <Box className="team-wrap">
                                      {isTennisImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "hometeam")}
                                        </span>
                                      )}
                                    </Box>
                                    <Box className="event-details-wrap">
                                      <span className="event-name">
                                        {item?.TennisTournament?.name}{" "}
                                      </span>
                                      <Box className="event-teams">
                                        {" "}
                                        {item?.eventName}
                                      </Box>
                                      <Box className="date-time-wrap">
                                        <Box className="event-date">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("DD/MM/YYYY")}
                                        </Box>
                                        <Box className="event-time">
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("hh:mm a")}
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box className="team-wrap">
                                      {isTennisImageLoading ? (
                                        <div className="allsport-loader-center">
                                          <Loader />
                                        </div>
                                      ) : (
                                        <span className="team-img-wrap">
                                          {fetchTeamlogo(item, "awayteam")}
                                        </span>
                                      )}
                                    </Box>
                                  </Box>

                                  <Countdown
                                    date={moment.utc(item?.startTime).toDate()}
                                    renderer={TeamsportsRenderer}
                                    onComplete={() =>
                                      setTimeout(
                                        () => fetchUpcomingTennisEvent(),
                                        2000
                                      )
                                    }
                                    key={moment.utc(item?.startTime).format()}
                                  />
                                </Box>
                              </Box>
                            </React.Fragment>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  ))}
              </Box>
              {NBAData?.length === 0 &&
              cricketData?.length === 0 &&
              RLData?.length === 0 &&
              RUData?.length === 0 &&
              AFLData?.length === 0 &&
              ARData?.length === 0 &&
              TennisData?.length === 0 &&
              BaseballData?.length === 0 &&
              IceHockeyData?.length === 0 &&
              BoxingData?.length === 0 &&
              MMAData?.length === 0 &&
              golfData?.length === 0 &&
              SoccerData?.length === 0 ? (
                <Box style={{ textAlign: "center", fontFamily: "inter" }}>
                  {" "}
                  {/* No Matches Available{" "} */}
                  <NoDataComp />
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        )}
      </Box>
      {fetchAds(
        1,
        Config?.release == "IN" ? "218px" : "102px",
        Config?.release == "IN" ? HomeAboveOurPartnerIndia : HomeAboveOurPartner
      )}
      <OurPartner />
      {Config?.release == "AU" && fetchAds(2, "102px", HomeBelowOurPartner)}
    </>
  );
}
