import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import banner from "../../../assets/images/banner/smart-b-banner-secodary.webp";
import bannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import { ReactComponent as Search } from "../../../assets/images/icons/search.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Reset } from "../../../assets/images/reset.svg";
import { IntlContext } from "src/App";
import moment from "moment-timezone";
import Select, { components } from "react-select";
import "./smartbook.scss";
import UpcomingMatches from "./upcomingMatches";
import Results from "./results";
import SmartBook from "./smartbook";
import _ from "lodash";
import SBSearch from "./smartbookSearchModal";
import SmartBookModal from "../UI/smartbookModal";
import { release } from "src/helpers/context/release";
import { Config } from "src/helpers/context";

const newTabData = [
  {
    id: 0,
    name: "Fixtures"
  },
  { id: 1, name: "Results" },
  {
    id: 2,
    name: "Smartbook"
  }
];
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const day = [
  {
    id: 1,
    name: "Today",
    date: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
    dayName: moment.tz(moment(), timezone).format("dddd")
  },
  {
    id: 2,
    name: "Tomorrow",
    date: moment
      .tz(moment(), timezone)
      .add(1, "days")
      .format("YYYY-MM-DD"),
    dayName: moment
      .tz(moment(), timezone)
      .add(1, "days")
      .format("dddd")
  },
  {
    id: 3,
    name: "Wednesday",
    date: moment
      .tz(moment(), timezone)
      .add(2, "days")
      .format("YYYY-MM-DD"),
    dayName: moment
      .tz(moment(), timezone)
      .add(2, "days")
      .format("dddd")
  },
  {
    id: 4,
    name: "Thursday",
    date: moment
      .tz(moment(), timezone)
      .add(3, "days")
      .format("YYYY-MM-DD"),
    dayName: moment
      .tz(moment(), timezone)
      .add(3, "days")
      .format("dddd")
  },
  {
    id: 0,
    name: "All"
  }
  // {
  //   id: 5,
  //   name: "Friday",
  //   date: moment
  //     .tz(moment(), timezone)
  //     .add(4, "days")
  //     .format("YYYY-MM-DD"),
  //   dayName: moment
  //     .tz(moment(), timezone)
  //     .add(4, "days")
  //     .format("dddd")
  // },
  // {
  //   id: 6,
  //   name: "Saturday",
  //   date: moment
  //     .tz(moment(), timezone)
  //     .add(5, "days")
  //     .format("YYYY-MM-DD"),
  //   dayName: moment
  //     .tz(moment(), timezone)
  //     .add(5, "days")
  //     .format("dddd")
  // }
];

const SortData = [
  { value: 0, label: "Date (Newest to Oldest)" },
  { value: 1, label: "Date (Oldest to Newest)" },
  { value: 2, label: "Alphabetical A-Z" },
  { value: 3, label: "Alphabetical Z-A" }
];
const PeriodData = [
  { value: 1, label: "last 30 days" },
  { value: 2, label: "July 2023" },
  { value: 3, label: "June 2023" },
  { value: 4, label: "May 2023" }
];
const EntryData = [
  { value: 0, label: "All" },
  { value: 1, label: "Runner" },
  { value: 2, label: "Jockey" },
  { value: 3, label: "Trainer" },
  { value: 4, label: "Tracks" }
];
const StateData = [
  { value: 1, label: "All" },
  { value: 2, label: "ACT" },
  { value: 3, label: "VIC" },
  { value: 4, label: "QLD" },
  { value: 5, label: "SA" },
  { value: 5, label: "WA" },
  { value: 5, label: "TAS" }
];

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const SmartbookPage = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [tabvalue, setTabValue] = useState(0);
  const [value, setValue] = useState(1);
  const [selectedSort, setselectedSort] = useState(0);
  const [selectedEntry, setselectedEntry] = useState(0);
  const [selectedPeriod, setselectedPeriod] = useState(0);
  const [count, setcount] = useState(0);
  const [upcomingData, setUpcomingData] = useState([]);
  const [isUpcomingLoading, setUpcomingLoading] = useState(false);
  const [upcomingCount, setUpcomingCount] = useState(0);
  const [upcomingPage, setUpcomingPage] = useState(1);
  const [resultData, setResultData] = useState([]);
  const [isresultLoading, setResultLoading] = useState(false);
  const [resultCount, setResultCount] = useState(0);
  const [resultPage, setResultPage] = useState(1);
  const [allBBData, setAllBBData] = useState([]);
  const [isAllBBLoading, setAllBBLoading] = useState(false);
  const [allBBCount, setAllBBCount] = useState(0);
  const [allBBPage, setAllBBPage] = useState(1);
  const [dateRanges, setDateRanges] = useState([]);
  const [isToggle, setisToggle] = useState(true);
  const [tabDate, setTabDate] = useState({
    startDate: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
    endDate: moment.tz(moment(), timezone).format("YYYY-MM-DD")
  });
  const [sortChange, setSortChange] = useState({
    key: "startTime",
    value: "desc"
  });
  const [BBtype, setBBtype] = useState("");
  const [periodChange, setPeriodChange] = useState({
    startDate: moment()
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD")
  });
  const [BBSearchopen, setBBSearchOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedSportId, setSelectedSportId] = useState("");
  const limit = 20;
  const [currentPage, setCurrentPage] = useState(0);
  const [sports, setSports] = useState([]);
  const [selectedSports, setSelectedSports] = useState(null);
  const [teamsAll, setTeamsAll] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [OrgAll, setOrgAll] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [isTeamSearch, setIsTeamSearch] = useState("");
  const [isTeamSelectOpen, setisTeamSelectOpen] = useState(false);
  const [searchTeam, setsearchTeam] = useState([]);
  const [searchTeamCount, setsearchTeamCount] = useState(0);
  const [SearchTeampage, setSearchTeampage] = useState(0);
  const [pageTeam, setpageTeam] = useState(0);
  const [teamApiCount, setTeamApiCount] = useState(0);
  const [isOrgSearch, setIsOrgSearch] = useState("");
  const [isOrgSelectOpen, setisOrgSelectOpen] = useState(false);
  const [searchOrg, setsearchOrg] = useState([]);
  const [searchOrgCount, setsearchOrgCount] = useState(0);
  const [SearchOrgpage, setSearchOrgpage] = useState(0);
  const [pageOrg, setpageOrg] = useState(0);
  const [OrgApiCount, setOrgApiCount] = useState(0);
  const [countOrg, setcountOrg] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [selectedStartDateDay, setselectedStartDateDay] = useState();
  const endDate = moment(Date())
    .add(6, "days")
    .format("YYYY-MM-DD");
  const handleBBSearchModal = () => {
    setBBSearchOpen(true);
  };
  const handleBBSearchClose = () => {
    setBBSearchOpen(false);
  };
  const handleTabChange = (event, value) => {
    setTabValue(value);
    if (value === 2) {
      setSortChange({
        key: "updatedAt",
        value: "desc"
      });
    }
  };

  const handleChange = (event, value) => {
    setValue(value);
    switch (value) {
      case 0:
        setTabDate({
          startDate: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
          endDate: null
        });
        break;
      case 1:
        setTabDate({
          startDate: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
          endDate: moment.tz(moment(), timezone).format("YYYY-MM-DD")
        });
        break;
      case 2:
        setTabDate({
          startDate: moment
            .tz(moment(), timezone)
            .add(1, "days")
            .format("YYYY-MM-DD"),
          endDate: moment
            .tz(moment(), timezone)
            .add(1, "days")
            .format("YYYY-MM-DD")
        });
        break;
      case 3:
        setTabDate({
          startDate: moment
            .tz(moment(), timezone)
            .add(2, "days")
            .format("YYYY-MM-DD"),
          endDate: moment
            .tz(moment(), timezone)
            .add(2, "days")
            .format("YYYY-MM-DD")
        });
        break;
      case 4:
        setTabDate({
          startDate: moment
            .tz(moment(), timezone)
            .add(3, "days")
            .format("YYYY-MM-DD"),
          endDate: moment
            .tz(moment(), timezone)
            .add(3, "days")
            .format("YYYY-MM-DD")
        });
        break;
      default:
        setTabDate({
          startDate: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
          endDate: moment.tz(moment(), timezone).format("YYYY-MM-DD")
        });
    }
  };
  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter(item => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  useEffect(() => {
    setselectedStartDateDay(
      moment(Date())
        .tz(timezone)
        .format("YYYY-MM-DD")
    );
    setCurrentPage(0);
    fetchSportData();
    fetchLast12Month();
  }, []);

  useEffect(() => {
    if (tabvalue === 0) {
      if (selectedSports) {
        fetchUpcomingBlackBook(selectedOrg, selectedTeams, 1);
      }
    } else if (tabvalue === 1) {
      fetchResultBlackBook(selectedOrg, selectedTeams, 1);
    } else if (tabvalue == 2) {
      fetchAllBlackBook(1);
    }
  }, [tabDate, sortChange, isToggle, periodChange]);

  useEffect(() => {
    handleResetAll();
  }, [tabvalue]);

  const fetchUpcomingBlackBook = async (tournament, team, page, sports) => {
    setUpcomingPage(page);
    try {
      setUpcomingLoading(true);
      const { status, data } = await axiosInstance.get(
        `/blackbook/sport/event?SportId=${
          sports ? sports : selectedSports
        }&comment=${isToggle}&startDate=${tabDate?.startDate}&endDate=${
          tabDate?.endDate ? tabDate?.endDate : ""
        }&orderBy=${sortChange?.value}&sort=${sortChange?.key}&tournamentId=${
          tournament ? tournament : ""
        }&teamId=${team ? team : ""}&limit=10&page=${page}&timeZone=${timezone}`
      );

      if (status === 200) {
        const count = data?.result?.count;
        setUpcomingCount(count);

        setUpcomingData(data?.result?.rows);
        setUpcomingLoading(false);
      } else {
        setUpcomingLoading(false);
      }
    } catch (err) {
      setUpcomingLoading(false);
    }
  };

  const fetchResultBlackBook = async (tournament, team, page, sports) => {
    setResultPage(page);
    try {
      setResultLoading(true);
      const { status, data } = await axiosInstance.get(
        `/blackbook/sport/event?SportId=${
          sports ? sports : selectedSports
        }&comment=${isToggle}&startDate=${periodChange?.startDate}&endDate=${
          periodChange?.endDate
        }&orderBy=${sortChange?.value}&sort=${sortChange?.key}&tournamentId=${
          tournament ? tournament : ""
        }&teamId=${
          team ? team : ""
        }&limit=10&page=${page}&status=finished&timeZone=${timezone}`
      );

      if (status === 200) {
        const count = data?.result?.count;
        setResultCount(count);
        setResultData(data?.result?.rows);
        setResultLoading(false);
      } else {
        setResultLoading(false);
      }
    } catch (err) {
      setResultLoading(false);
    }
  };
  const fetchAllBlackBook = async (page, sports) => {
    setAllBBPage(page);
    try {
      setAllBBLoading(true);
      const { status, data } = await axiosInstance.get(
        `blackbook/favoriteData/sport?sort=${sortChange?.key}&orderBy=${
          sortChange?.value
        }&SportId=${
          sports ? sports : selectedSports
        }&comment=${isToggle}&limit=10&page=${page}`
      );

      if (status === 200) {
        const count = data?.result?.count;
        setAllBBCount(count);

        setAllBBData(data?.result?.result);
        setAllBBLoading(false);
      } else {
        setAllBBLoading(false);
      }
    } catch (err) {
      setAllBBLoading(false);
    }
  };

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  const handlePaginationClick = (event, page) => {
    fetchUpcomingBlackBook(selectedOrg, selectedTeams, Number(page));
  };
  const pageNumbers = [];

  if (upcomingCount > 0) {
    for (let i = 1; i <= Math.ceil(upcomingCount / 10); i++) {
      pageNumbers.push(i);
    }
  }
  const handleResultPaginationClick = (event, page) => {
    fetchResultBlackBook(selectedOrg, selectedTeams, Number(page));
  };

  const resultpageNumbers = [];

  if (resultCount > 0) {
    for (let i = 1; i <= Math.ceil(resultCount / 10); i++) {
      resultpageNumbers.push(i);
    }
  }

  const handleAllBBPaginationClick = (event, page) => {
    fetchAllBlackBook(Number(page));
  };

  const allBBpageNumbers = [];

  if (allBBCount > 0) {
    for (let i = 1; i <= Math.ceil(allBBCount / 10); i++) {
      allBBpageNumbers.push(i);
    }
  }
  const fetchLast12Month = () => {
    const currentDate = moment();
    const last12MonthsWithDates = [];

    for (let i = 0; i < 12; i++) {
      const startOfMonth = currentDate
        .clone()
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth =
        i === 0
          ? moment().format("YYYY-MM-DD")
          : currentDate
              .clone()
              .endOf("month")
              .format("YYYY-MM-DD");
      const Month = currentDate.format("MMM YYYY");

      last12MonthsWithDates.push({
        label: Month,
        startDate: startOfMonth,
        endDate: endOfMonth,
        value: i
      });

      currentDate.subtract(1, "month");
    }
    setDateRanges(last12MonthsWithDates);
  };

  const handlePeriodChange = e => {
    setselectedPeriod(e?.value);
    setPeriodChange(e);
  };

  const handleResetAll = () => {
    const allSports = sports?.filter(item => item?.isAll);
    setSelectedSports(allSports?.[0]?.value);
    setselectedSort(0);
    setSortChange({
      key: tabvalue === 2 ? "updatedAt" : "startTime",
      value: "desc"
    });
    setselectedEntry(0);
    setBBtype("");
    setSelectedOrg(null);
    setSelectedTeams(null);
    setPeriodChange({
      startDate: moment()
        .clone()
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD")
    });
  };

  const Today = moment.tz(moment(), timezone).format("YYYY-MM-DD");
  const Tommorow = moment
    .tz(moment(), timezone)
    .add(1, "days")
    .format("YYYY-MM-DD");

  const fetchOrgData = async (page, sID, OrgAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/tournament?SportId=${sID}&offset=${page}&limit=${limit}`
      );
      if (status === 200) {
        setOrgApiCount(teamApiCount + 1);
        setcountOrg(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id
          });
        });
        let filterData = _.unionBy(OrgAll, newdata);
        let allteamdatas = filterData?.unshift({
          label: "All Tournaments",
          value: 0
        });
        setOrgAll(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      }
    } catch (err) {}
  };

  const getFields = (list, field) => {
    return list.reduce(function(carry, item) {
      if (typeof item === "object" && field in item) {
        carry.push(item[field]);
      }
      return carry;
    }, []);
  };

  const fetchSportData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `/sports/sport/?sportTypeId=${2}`
      );
      if (status === 200) {
        const releaseFilterSportData = data?.result?.filter(item =>
          release[Config.release]?.sportId?.includes(item?.id)
        );
        var sportsdata = releaseFilterSportData?.map(s => {
          return { ...s, label: s?.sportName, value: s?.id };
        });
        var filterSports = getFields(sportsdata, "id");
        setSelectedSports(filterSports);
        fetchUpcomingBlackBook(selectedOrg, selectedTeams, 1, filterSports);
        var sdata = _.orderBy(sportsdata, ["label"], ["asc"]);
        var mergedArray = _.concat(
          [{ label: "ALL", value: filterSports, isAll: true }],
          sdata
        );
        setSports(mergedArray);
      }
    } catch (err) {}
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const handleBlackBookModal = (title, id, type, sportId) => {
    setSelectedTitle(title);
    setSelectedType(type);
    setSelectedId(id);
    setSelectedSportId(sportId);
    handleModal();
  };
  const handleModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnScrollBottomTeams = () => {
    if (
      isTeamSearch !== "" &&
      searchTeamCount !== Math.ceil(SearchTeampage / 20)
    ) {
      handleTeamInputChange(SearchTeampage + 20, isTeamSearch, selectedSports);

      setSearchTeampage(SearchTeampage + 20);
    } else {
      if (count !== 0 && count !== Math.ceil(pageTeam / 20 + 1)) {
        fetchTeamData(pageTeam + 20, selectedSports, teamsAll, selectedOrg);
        setpageTeam(pageTeam + 20);
      }
    }
  };
  const handleOrgInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/tournament?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchOrgCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchOrg, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchOrg(filterData);
          setIsOrgSearch(value);
        }
      });
  };
  const handleOnScrollBottomOrg = () => {
    if (
      isOrgSearch !== "" &&
      searchOrgCount !== Math.ceil(SearchOrgpage / 20)
    ) {
      handleOrgInputChange(SearchOrgpage + 20, isOrgSearch, selectedSports);

      setSearchOrgpage(SearchOrgpage + 20);
    } else {
      if (countOrg !== 0 && countOrg !== Math.ceil(pageOrg / 20 + 1)) {
        fetchOrgData(pageOrg + 20, selectedSports, OrgAll);
        setpageOrg(pageOrg + 20);
      }
    }
  };
  const handleTeamInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/allsport/team?SportId=${sid}&limit=${limit}&offset=${page}&search=${value}`
      )
      .then(res => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchTeamCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map(item => {
            newdata.push({
              label: item?.name,
              value: item?.id
            });
          });
          const mergeData = _.unionBy(searchTeam, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchTeam(filterData);
          // setsearchTeam(
          //   _.uniqBy(filterData, function(e) {
          //     return e.value;
          //   })
          // );
          setIsTeamSearch(value);
        }
      });
  };

  const fetchTeamData = async (page, sID, teamsAll, tournamentId) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/allsport/team?SportId=${sID}&offset=${page}&limit=${limit}&tournamentId=${tournamentId}`
      );
      if (status === 200) {
        setTeamApiCount(teamApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map(item => {
          newdata.push({
            label: item?.name,
            value: item?.id
          });
        });
        let filterData = _.unionBy(teamsAll, newdata);
        let allteamdatas = filterData?.unshift({
          label: "All Teams",
          value: 0
        });
        setTeamsAll(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      }
    } catch (err) {}
  };

  const handleSortchange = e => {
    setselectedSort(e?.value);
    switch (e?.value) {
      case 0:
        setSortChange({
          key: tabvalue === 2 ? "updatedAt" : "startTime",
          value: "desc"
        });
        break;
      case 1:
        setSortChange({
          key: tabvalue === 2 ? "updatedAt" : "startTime",
          value: "asc"
        });
        break;
      case 2:
        setSortChange({
          key: tabvalue === 2 ? "name" : "eventName",
          value: "asc"
        });
        break;
      case 3:
        setSortChange({
          key: tabvalue === 2 ? "name" : "eventName",
          value: "desc"
        });
        break;
      default:
        setSortChange({
          key: tabvalue === 2 ? "updatedAt" : "startTime",
          value: "desc"
        });
    }
  };
  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds(
            "218px",
            Config?.release == "IN" ? bannerIndia : banner
          )}
        </Box>
        <Box className="blackbook-wrap">
          <Box className="blackbook-header">
            <Box className="bredcrumn-wrap">
              <Breadcrumbs
                separator="/"
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                <Link underline="hover" color="inherit" to="/">
                  {/* {localesData?.MENU?.HOME} */}
                  HOME
                </Link>

                <Typography>
                  {/* {localesData?.BLACKBOOK?.BLACKBOOK} */}
                  SPORTS SMARTBOOK
                </Typography>
              </Breadcrumbs>
            </Box>
            <Typography variant="h1">
              {/* {localesData?.BLACKBOOK?.BLACKBOOK} */}
              SPORTS SMARTBOOK
            </Typography>
          </Box>
          <Box className="tab-search-section">
            <Box>
              <TextField
                // placeholder={localesData?.BLACKBOOK?.SEARCH_PLACEHOLDER}
                placeholder="Search to add to your Smartbook"
                variant="outlined"
                type="text"
                name="search"
                className="search_input"
                onKeyPress={() => handleBBSearchModal()}
                onClick={() => handleBBSearchModal()}
                // onChange={e => {
                //   setSearchInput(e.target.value);
                //   fetchTrackList(selectedOrg, selectedTeams, e.target.value);
                // }}
                // values={searchInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box className="team-sports-tab">
              <Tabs
                value={tabvalue}
                variant="scrollable"
                scrollButtons="off"
                indicatorColor="primary"
                textColor="primary"
                className="teamsport-tab-detail"
              >
                {newTabData?.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Tab
                        label={item?.name}
                        value={item?.id}
                        className={item?.id === tabvalue ? "active " : ""}
                        onChange={(event, newValue) =>
                          handleTabChange(event, item?.id)
                        }
                      />
                    </Box>
                  );
                })}
              </Tabs>
            </Box>
            {tabvalue === 0 ? (
              <>
                <Tabs
                  value={value}
                  variant="scrollable"
                  scrollButtons="off"
                  indicatorColor="primary"
                  textColor="primary"
                  className="day-tab-details"
                >
                  {day?.map((item, index) => {
                    return (
                      <Box key={index}>
                        <Tab
                          disableRipple
                          disableFocusRipple
                          label={
                            item?.name === "All"
                              ? "All"
                              : item?.date === Today
                              ? "Today"
                              : item?.date === Tommorow
                              ? "Tommorow"
                              : item?.dayName
                          }
                          value={item?.id}
                          className={item?.id == value ? "active" : ""}
                          onChange={(event, newValue) =>
                            handleChange(event, item?.id)
                          }
                        />
                      </Box>
                    );
                  })}
                </Tabs>
              </>
            ) : (
              <></>
            )}
          </Box>
          <Box className="page-deatils-wrap">
            <Box className="filter-section">
              <Select
                className="React sort-select"
                value={SortData?.find(item => {
                  return item?.value === selectedSort;
                })}
                onChange={e => handleSortchange(e)}
                options={SortData}
                classNamePrefix="select"
                placeholder="Sort: Date (Newest to Oldest)"
                components={{ DropdownIndicator }}
              />
              <Select
                className="React Entry-select"
                value={sports?.find(item => {
                  return item?.value === selectedSports;
                })}
                onChange={e => {
                  setSelectedSports(e?.value == 0 ? "" : e?.value);
                  setCurrentPage(0);
                  setSelectedTeams(null);
                  setSelectedOrg(null);
                  setTeamsAll([]);
                  setOrgAll([]);
                  if (tabvalue === 0) {
                    fetchUpcomingBlackBook(null, null, 1, e?.value);
                  } else if (tabvalue === 1) {
                    fetchResultBlackBook(null, null, 1, e?.value);
                  } else if (tabvalue == 2) {
                    fetchAllBlackBook(1, e?.value);
                  }
                  if (!e?.isAll) {
                    fetchOrgData(0, e?.value, []);
                    // fetchTeamData(0, e?.value, []);
                  }
                }}
                // onMenuScrollToBottom={e => handleOnScrollBottomSports(e)}
                options={sports}
                classNamePrefix="select"
                placeholder="Sports"
                // isSearchable={false}
                // onFocus={() => setTournamentSelect(true)}
                // onBlur={() => setTournamentSelect(false)}
                components={{ DropdownIndicator }}
              />
              {tabvalue === 0 || tabvalue === 2 ? (
                <></>
              ) : (
                <Select
                  className="React Period-select"
                  value={dateRanges?.find(item => {
                    return item?.value === selectedPeriod;
                  })}
                  onChange={e => {
                    handlePeriodChange(e);
                  }}
                  options={dateRanges}
                  classNamePrefix="select"
                  placeholder="Period: Last 30 days"
                  // isSearchable={false}
                  components={{ DropdownIndicator }}
                />
              )}
              {/* <Select
                className="React Entry-select"
                value={EntryData?.find(item => {
                  return item?.value === selectedEntry;
                })}
                // onChange={e => handleTypechange(e)}
                options={EntryData}
                classNamePrefix="select"
                placeholder="Sport: All"
                // isSearchable={false}
                components={{ DropdownIndicator }}
              /> */}
              {tabvalue === 2 ? (
                <></>
              ) : (
                <>
                  <Select
                    className={
                      selectedSports && selectedSports.length > 0
                        ? `React country-select disable-state`
                        : `React country-select`
                    }
                    onMenuScrollToBottom={e => handleOnScrollBottomOrg(e)}
                    onInputChange={e =>
                      handleOrgInputChange(0, e, selectedSports)
                    }
                    onChange={e => {
                      setSelectedOrg(e?.value);
                      // fetchOrgData(e?.value, selectedSports, OrgAll);
                      e?.value !== 0 &&
                        fetchTeamData(0, selectedSports, [], e?.value);
                      setSelectedTeams(null);
                      setTeamsAll([]);
                      setOrgApiCount(0);
                      setpageOrg(0);
                      setisOrgSelectOpen(false);
                      setCurrentPage(0);
                      if (tabvalue === 0) {
                        fetchUpcomingBlackBook(e?.value, null, upcomingPage);
                      } else if (tabvalue === 1) {
                        fetchResultBlackBook(e?.value, null, resultPage);
                      }
                    }}
                    onFocus={() => setisOrgSelectOpen(true)}
                    onBlur={() => setisOrgSelectOpen(false)}
                    value={
                      selectedOrg &&
                      (isOrgSearch
                        ? searchOrg?.find(item => {
                            return item?.value == selectedOrg;
                          })
                        : OrgAll?.find(item => {
                            return item?.value == selectedOrg;
                          }))
                    }
                    options={isOrgSearch ? searchOrg : OrgAll}
                    classNamePrefix="select"
                    placeholder="Tournament: All"
                    isDisabled={
                      selectedSports && selectedSports.length > 0 ? true : false
                    }
                    components={{ DropdownIndicator }}
                  />
                  <Select
                    className={
                      selectedOrg
                        ? `React State-select`
                        : `React State-select disable-state`
                    }
                    // options={teamsAll}
                    onMenuScrollToBottom={e => handleOnScrollBottomTeams(e)}
                    onInputChange={e =>
                      handleTeamInputChange(0, e, selectedSports)
                    }
                    onChange={e => {
                      setSelectedTeams(e?.value);
                      // fetchTeamData(e?.value, selectedSports, teamsAll);
                      setTeamApiCount(0);
                      setpageTeam(0);
                      setHasMore(false);
                      setisTeamSelectOpen(false);
                      setCurrentPage(0);
                      if (tabvalue === 0) {
                        fetchUpcomingBlackBook(
                          selectedOrg,
                          e?.value,
                          upcomingPage
                        );
                      } else if (tabvalue === 1) {
                        fetchResultBlackBook(selectedOrg, e?.value, resultPage);
                      }
                    }}
                    onFocus={() => setisTeamSelectOpen(true)}
                    onBlur={() => setisTeamSelectOpen(false)}
                    value={
                      selectedTeams &&
                      (isTeamSearch
                        ? searchTeam?.find(item => {
                            return item?.value == selectedTeams;
                          })
                        : teamsAll?.find(item => {
                            return item?.value == selectedTeams;
                          }))
                    }
                    options={isTeamSearch ? searchTeam : teamsAll}
                    classNamePrefix="select"
                    placeholder="Teams: All"
                    isDisabled={selectedOrg ? false : true}
                    components={{ DropdownIndicator }}
                  />
                </>
              )}
              <Box className="switch-box">
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      checked={isToggle}
                      onChange={e => setisToggle(e.target.checked)}
                    />
                  }
                  label="Comments"
                  labelPlacement="start"
                />
              </Box>
              <Box
                className="reset-all cursor-pointer"
                onClick={() => handleResetAll()}
              >
                <Reset />
                <Typography className="reset-all-text">Reset All</Typography>
              </Box>
            </Box>

            {tabvalue === 0 ? (
              <UpcomingMatches
                upcomingData={upcomingData}
                isUpcomingLoading={isUpcomingLoading}
                fetchUpcomingBlackBook={fetchUpcomingBlackBook}
                selectedOrg={selectedOrg}
                selectedTeams={selectedTeams}
                handlePaginationClick={handlePaginationClick}
                upcomingCount={upcomingCount}
                upcomingPage={upcomingPage}
                pageNumbers={pageNumbers}
                isToggle={isToggle}
              />
            ) : tabvalue === 1 ? (
              <Results
                resultData={resultData}
                isresultLoading={isresultLoading}
                fetchResultBlackBook={fetchResultBlackBook}
                selectedOrg={selectedOrg}
                selectedTeams={selectedTeams}
                handlePaginationClick={handleResultPaginationClick}
                resultCount={resultCount}
                resultPage={resultPage}
                pageNumbers={resultpageNumbers}
                isToggle={isToggle}
              />
            ) : (
              <SmartBook
                allSBData={allBBData}
                isAllSBLoading={isAllBBLoading}
                fetchAllSmartBook={fetchAllBlackBook}
                handlePaginationClick={handleAllBBPaginationClick}
                allSBCount={allBBCount}
                allSBPage={allBBPage}
                pageNumbers={allBBpageNumbers}
                isToggle={isToggle}
              />
            )}
          </Box>
        </Box>
      </Box>
      <SBSearch
        open={BBSearchopen}
        handleClose={handleBBSearchClose}
        handleBlackBookModal={handleBlackBookModal}
        closeIcon={true}
      />
      <SmartBookModal
        open={open}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        dataId={selectedId}
        sportId={selectedSportId}
      />
    </>
  );
};

export default SmartbookPage;
