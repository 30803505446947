import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
// import RoundPlaceHolder from "../../../../../assets/images/roundPlaceholder.png";
// import Individual1 from "../../../../../assets/images/individual1.png";
// import Individual2 from "../../../../../assets/images/individual2.png";
// import Placeholder from "../../../../../assets/images/placeOrder.png";
import { ReactComponent as Watch } from "src/assets/images/watch.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Link } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import OurPartner from "src/views/component/ourPartners";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import BetweenContent from "../../../../../assets/images/ad-placeholder/sports2.webp";
import AboveOurPartner from "../../../../../assets/images/ad-placeholder/sports5.webp";
import BelowOurPartner from "../../../../../assets/images/ad-placeholder/homebelow-ourpartner.webp";
import BannerIndia from "../../../../../assets/images/ad-placeholder/IPL_Page_Banner_894x218_4.jpg";
import DefaultImg from "../../../../../assets/images/smartb_default.png";
import Author_Placeholder from "../../../../../assets/images/SmartB_author.png";
import moment from "moment";
import Loader from "../../../../../components/Loader";
import { Tweet } from "react-twitter-widgets";
import { fetchNews } from "src/helpers/store/Actions/NewsRelated";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import DynamicNewsScript from "./DynamicNewsScript";
import { Config } from "src/helpers/context";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const IndividualNews = ({ categoryList }) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [AdData, setAdData] = useState([]);
  const [singleNewsData, setSingleNewsData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [categoryListData, setCategoryListData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchAdsData();
    fetchSingleNews();
  }, []);

  useEffect(() => {
    fetchSingleNews();
  }, [params?.categoryId, params?.id]);
  // const handleAdsImression = async ids => {
  //   try {
  //     const payload = {
  //       ids: ids
  //     };
  //     const { status, data } = await axiosInstance.post(
  //       `addImpression`,
  //       payload
  //     );
  //     if (status === 200) {
  //     }
  //   } catch (err) {}
  // };

  const fetchAdsData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=15&type=web&timeZone=${timezone}`
      );
      if (status === 200) {
        setAdData(data?.result);
        // let ids = [];
        // let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
        //   ids.push(item?.id);
        // });
        // if (ids.length > 0) {
        //   handleAdsImression(ids);
        // }
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const settings1 = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    prevArrow: (
      <Box className="slick-prev">
        <ChevronLeftIcon />
      </Box>
    ),
    nextArrow: (
      <Box className="slick-next">
        <ChevronRightIcon />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 439,
        settings: {
          // autoplay: true,
          // autoplaySpeed: 2500,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true
        }
      }
    ]
  };

  const fetchSingleNews = async () => {
    setLoading(true);

    try {
      let endpoint;
      if (token) {
        endpoint = `v2/news/preview/articles/${params?.id}`;
      } else {
        endpoint = `v2/news/articles/${params?.id}`;
      }

      const response = await axiosInstance.get(endpoint, {
        headers: token ? { Authorization: `Bearer ${token}` } : undefined
      });

      const { status, data } = response;

      if (status === 200) {
        setSingleNewsData(data?.result);
        dispatch(fetchNews(data?.result));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchNewsTime = newsdate => {
    const now = moment();
    const givenTime = moment(newsdate);
    const timeDiffMinutes = now.diff(givenTime, "minutes");
    const timeDiffHours = now.diff(givenTime, "hours");
    const timeDiffDays = now.diff(givenTime, "days");
    const timeDiffWeeks = now.diff(givenTime, "weeks");
    if (timeDiffMinutes > 60) {
      if (timeDiffMinutes / 60 > 24) {
        if (timeDiffDays > 7) {
          return `${timeDiffWeeks} ${
            timeDiffWeeks == 1 ? "week" : "weeks"
          } ago`;
        } else {
          return `${timeDiffDays} ${timeDiffDays == 1 ? "day" : "days"} ago`;
        }
      } else {
        return `${timeDiffHours} hours ago`;
      }
    } else {
      return `${timeDiffMinutes} minutes ago`;
    }
  };

  useEffect(() => {
    if (categoryList?.length > 0) {
      setCategoryListData(categoryList);
    }
  }, [categoryList, params?.categoryId]);

  const fetchCategoryName = id => {
    let categoryName = categoryListData?.filter(item => item?.value == id);
    return categoryName?.[0]?.label;
  };

  return (
    <>
      {isLoading ? (
        <div className="app-loader">
          <Loader />
        </div>
      ) : (
        <>
          <Box className="news-full-details">
            <Typography
              variant="h4"
              dangerouslySetInnerHTML={{
                __html: singleNewsData?.title
              }}
            ></Typography>
            <Box className="news-sub-details">
              <Box className="news-sub-left">
                <Box className="news-logo">
                  {singleNewsData?.publishedBy?.logo ? (
                    singleNewsData?.NewsProviderId === 2 ||
                    singleNewsData?.NewsProviderId === 3 ? (
                      <img
                        src={singleNewsData?.publishedBy?.logo}
                        alt="logo"
                        width="100%"
                      />
                    ) : (
                      <img
                        src={
                          singleNewsData?.publishedBy?.logo?.includes("uploads")
                            ? singleNewsData?.publishedBy?.logo
                            : `https://www.livescore.com${singleNewsData?.publishedBy?.logo}`
                        }
                        alt="logo"
                        width="100%"
                      />
                    )
                  ) : (
                    <img src={Author_Placeholder} alt="logo" width="100%" />
                  )}
                </Box>
                <Box>
                  <Typography className="name">
                    {singleNewsData?.authors?.[0]?.name}
                  </Typography>
                  <Typography variant=" " className="live-score">
                    {singleNewsData?.publishedBy?.name}
                  </Typography>
                </Box>
              </Box>
              <Box className="news-sub-right">
                <Typography className="date">
                  {" "}
                  {moment
                    .utc(singleNewsData?.rapidCreatedAt)
                    .local()
                    .format("DD/MM/YYYY")}
                </Typography>
                <Typography variant="span" className="time">
                  {moment
                    .utc(singleNewsData?.rapidCreatedAt)
                    .local()
                    .format("hh:mm A")}
                </Typography>
              </Box>
            </Box>
            <Box className="news-img">
              {singleNewsData?.mainMedia?.gallery ||
              singleNewsData?.mainMedia?.[0]?.gallery ? (
                <img
                  src={
                    singleNewsData?.mainMedia?.gallery
                      ? singleNewsData?.mainMedia?.gallery?.url &&
                        singleNewsData?.mainMedia?.gallery?.url !== ""
                        ? singleNewsData?.mainMedia?.gallery?.url
                        : DefaultImg
                      : singleNewsData?.mainMedia?.[0]?.gallery
                      ? singleNewsData?.mainMedia?.[0]?.gallery?.url &&
                        singleNewsData?.mainMedia?.[0]?.gallery?.url !== ""
                        ? singleNewsData?.mainMedia?.[0]?.gallery?.url
                        : DefaultImg
                      : DefaultImg
                  }
                  alt={
                    singleNewsData?.mainMedia?.gallery
                      ? singleNewsData?.mainMedia?.gallery?.alt
                      : singleNewsData?.mainMedia?.[0]?.gallery
                      ? singleNewsData?.mainMedia?.[0]?.gallery?.alt
                      : ""
                  }
                  width="100%"
                />
              ) : (
                <></>
              )}
              {singleNewsData?.mainMedia?.gallery ||
              singleNewsData?.mainMedia?.[0]?.gallery ? (
                <Typography
                  className="img-alt"
                  dangerouslySetInnerHTML={{
                    __html: singleNewsData?.mainMedia?.gallery
                      ? singleNewsData?.mainMedia?.gallery?.alt
                      : singleNewsData?.mainMedia?.[0]?.gallery
                      ? singleNewsData?.mainMedia?.[0]?.gallery?.alt
                      : ""
                  }}
                >
                  {/* {singleNewsData?.mainMedia?.gallery
                    ? singleNewsData?.mainMedia?.gallery?.alt
                    : singleNewsData?.mainMedia?.[0]?.gallery
                    ? singleNewsData?.mainMedia?.[0]?.gallery?.alt
                    : ""}
                    : ""} */}
                </Typography>
              ) : (
                <></>
              )}
            </Box>
            {singleNewsData?.NewsProviderId === 2 ||
            singleNewsData?.NewsProviderId === 3 ? (
              <Box className="custom-news">
                <DynamicNewsScript newsContent={singleNewsData?.body} />
                {/* <Typography
                  className="para-1"
                  dangerouslySetInnerHTML={{
                    __html: singleNewsData?.body
                  }}
                  ref={advRef}
                ></Typography> */}
              </Box>
            ) : (
              singleNewsData?.body?.map((item, index) => {
                return (
                  <>
                    {item?.type === "editor_block" ? (
                      <Typography
                        className="para-1"
                        dangerouslySetInnerHTML={{
                          __html: item?.data?.content
                        }}
                      ></Typography>
                    ) : item?.type === "image" ? (
                      <Box className="news-img-2">
                        <img
                          src={item?.image?.gallery?.url}
                          alt={item?.image?.gallery?.alt}
                        />
                        <Typography className="img-alt">
                          {item?.image?.gallery?.alt}
                        </Typography>
                      </Box>
                    ) : item?.type === "link" ? (
                      <a href={item?.data?.link} target="_blank">
                        {item?.data?.text}
                      </a>
                    ) : item?.type === "embed" ? (
                      item?.data?.embed_type === "social" ? (
                        <Tweet
                          options={{ align: "center" }}
                          tweetId={item?.data?.content?.slice(8)}
                        />
                      ) : /* <Box className="ifeame-section"> 
                        <iframe
                          scrolling="no"
                          frameborder="0"
                          allowtransparency="true"
                          allowfullscreen="true"
                          src={`https://platform.twitter.com/embed/Tweet.html?dnt=false&embedId=twitter-widget-1&frame=false&hideCard=false&hideThread=false&id=${item?.data?.content?.slice(
                            8
                          )}`}
                          width="550px"
                          // height="576px"
                          className="twitter-tag"
                        ></iframe> 
                        
                      </Box>*/

                      item?.data?.embed_type === "other" ? (
                        <Box
                          className="instagram-post"
                          dangerouslySetInnerHTML={{
                            __html: item?.data?.content
                          }}
                        ></Box>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {index == 5 ? (
                      fetchAds(
                        1,
                        "100%",
                        Config?.release == "IN" ? BannerIndia : BetweenContent
                      )
                    ) : (
                      <></>
                    )}
                  </>
                );
              })
            )}
            <Box className="tages-details">
              {singleNewsData?.NewsTagRelations?.length > 0 ? (
                <Typography className="tages">Tags:</Typography>
              ) : (
                <></>
              )}
              <Box className="tage-box">
                {singleNewsData?.NewsTagRelations?.map(obj => (
                  <span className="news-tages">{obj?.NewsTag?.title}</span>
                ))}
              </Box>
            </Box>
          </Box>

          {/* {screenWidth > 1090 ? (
            " "
          ) : (
            <Box className="related-articles-layout">
              <Box className="related-articles-header">
                <Typography variant="h4">Related Articles</Typography>
              </Box>
              {singleNewsData?.NewsRelatedArticles?.length > 0 ? (
                <Slider {...settings1}>
                  {singleNewsData?.NewsRelatedArticles?.map((item, index) => (
                    <Grid item lg={6} key={index}>
                      <Box
                        className="single-top-stories"
                        onClick={() =>
                          navigate(
                            `/news/${singleNewsData?.NewsCategoryId}/${item?.relatedArticleId}`
                          )
                        }
                      >
                        <Box className="top-stories-img">
                          <img
                            src={
                              item?.NewsArticle?.mainMedia?.gallery
                                ? item?.NewsArticle?.mainMedia?.gallery?.url !==
                                  ""
                                  ? item?.NewsArticle?.mainMedia?.gallery?.url
                                  : DefaultImg
                                : item?.NewsArticle?.mainMedia?.[0]?.gallery
                                ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                    ?.url !== ""
                                  ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                      ?.url
                                  : DefaultImg
                                : DefaultImg
                            }
                            alt={
                              item?.NewsArticle?.mainMedia?.gallery
                                ? item?.NewsArticle?.mainMedia?.gallery?.alt
                                : item?.NewsArticle?.mainMedia?.[0]?.gallery
                                ? item?.NewsArticle?.mainMedia?.[0]?.gallery.alt
                                : ""
                            }
                          />
                        </Box>
                        <Box className="stories-details">
                          <Typography
                            className="details"
                            dangerouslySetInnerHTML={{
                              __html: item?.NewsArticle?.title
                            }}
                          ></Typography>
                          <Box className="text-details">
                            <span className="stories-tag">
                              {fetchCategoryName(singleNewsData.NewsCategoryId)}
                            </span>
                            <Typography className="stories-time">
                              <span className="watch">
                                <Watch />
                              </span>
                              {fetchNewsTime(item?.NewsArticle?.rapidCreatedAt)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Slider>
              ) : (
                <Box className="no-data">
                  <Typography>No Data Available</Typography>
                </Box>
              )}
            </Box>
          )} */}
        </>
      )}

      {fetchAds(
        2,
        "100%",
        Config?.release == "IN" ? BannerIndia : AboveOurPartner
      )}
      <OurPartner />
      {Config?.release == "AU" && fetchAds(3, "102px", BelowOurPartner)}
    </>
  );
};

export default IndividualNews;
