import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  Checkbox,
  TextareaAutosize
} from "@material-ui/core";
import { ReactComponent as RightArrow } from "../../../assets/images/orange-right-arrow.svg";
import { ReactComponent as OrangeDelete } from "../../../assets/images/orange-delete.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as Datepicker } from "src/assets/images/eventcalendar/Datepicker.svg";
import { ReactComponent as Timepicker } from "src/assets/images/eventcalendar/Timepicker.svg";
import { ReactComponent as Unchecked } from "../../../assets/images/icons/smartb-uncheck.svg";
import { ReactComponent as Checked } from "../../../assets/images/icons/smartb-check.svg";
import { ReactComponent as AddButton } from "../../../assets/images/round-add-icon.svg";
import compImage from "../../../assets/images/round-dummy-image.png";
import Select, { components } from "react-select";
import { Config } from "src/helpers/context";
import Loader from "src/components/Loader";
import { useParams } from "react-router";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import DialogBox from "../UI/dialogBox";
import { ToastContainer, toast } from "react-toastify";
import { Formik } from "formik";
import _ from "lodash";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import DateFnsUtils from "@date-io/date-fns";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
  KeyboardTimePicker,
  TimePicker
} from "@material-ui/pickers";
import AdBannner from "../AdBanner/AdBanner";
import tippingAbove from "../../../assets/images/ad-placeholder/tipping1.png";
import tippingBelow from "../../../assets/images/ad-placeholder/tipping2.png";
import BannerIndia from "../../../assets/images/ad-placeholder/IPL_Page_Banner_894x218_4.jpg";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment-timezone";
import UploadImageModal from "../UI/uploadImage";

const compDetails = {
  id: 1,
  sport: "Australian Rules",
  compCode: "AB12C3",
  league: "AFL",
  compName: "AFL Super League",
  startingRound: "Round 1",
  privacy: "Private",
  tipDeadline: "Round Based",
  ifNoTip: "All Away Teams auto picked",
  perfectRound: 1,
  jokerRound: 1,
  marginSystem: 1
};

const dayOptions = [
  {
    label: "Sunday",
    value: 1
  },
  {
    label: "Monday",
    value: 2
  },
  {
    label: "Tuesday",
    value: 3
  },
  {
    label: "Wednesday",
    value: 4
  },
  {
    label: "Thursday",
    value: 5
  },
  {
    label: "Friday",
    value: 6
  },
  {
    label: "Saturday",
    value: 7
  }
];

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const privacyOptions = [
  {
    label: "Public",
    value: "public"
  },
  {
    label: "Private",
    value: "private"
  }
];
const tipDeadlineOptions = [
  {
    label: "Round Based",
    value: "round"
  },
  {
    label: "Game Based",
    value: "game"
  }
];
const ifNoTipOptions = [
  {
    label: "Zero Score for round",
    value: "ZERO_SCORE_ROUND"
  },
  {
    label: "Lowest Score for round",
    value: "LOWEST_SCORE_ROUND"
  },
  {
    label: "All Away Teams auto picked",
    value: "AWAY_TEAM_PICKED"
  },
  {
    label: "All Home Teams auto picked",
    value: "HOME_TEAM_PICKED"
  }
];

const roundOptions = [
  {
    label: "Round 1",
    value: 1
  }
];

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const CompDetailsTab = ({
  eventByIdData,
  redux_userData,
  roundsData,
  newRoundOptions,
  noRoundData,
  getEventByID,
  stepperCount
}) => {
  const params = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDoneModal, setOpenDoneModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const ReduxAdsData = useSelector(state => state?.reduxData?.AdsData);
  const [compName, setCompName] = useState("");
  const [open, setOpen] = useState(false);
  const [timeOpen, setTimeOpen] = useState(false);
  const [openSocialShareModal, setOpenSocialShareModal] = useState(false);
  const [formvalues, setformvalues] = useState({});
  const [dateSelected, setDateSelected] = useState("");
  const [selectedTime, handleTimeChange] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [dateToCheck, setDateToCheck] = useState(null);
  const [isTipsterShown, setIsTipsterShown] = useState([
    {
      id: 1,
      isTipsterShown: "Show",
      value: true
    },
    {
      id: 2,
      isTipsterShown: "Hide",
      value: false
    }
  ]);
  const [selectedTipsterShown, setSelectedTipsterShown] = useState(null);
  const [roundBonus, setRoundBonus] = useState([
    {
      id: 1,
      Roundvalue: "Yes"
    },
    {
      id: 0,
      Roundvalue: "No"
    }
  ]);
  const [jokerBonus, setJokerBonus] = useState([
    {
      id: 1,
      Jokervalue: "Yes"
    },
    {
      id: 0,
      Jokervalue: "No"
    }
  ]);
  const [marginSystem, setMarginSystem] = useState([
    {
      id: 1,
      MarginSystemvalue: "Yes"
    },
    {
      id: 0,
      MarginSystemvalue: "No"
    }
  ]);
  const [rounds, setRounds] = useState(newRoundOptions);
  const [compAbout, setCompAbout] = useState(eventByIdData?.about);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [AdData, setAdData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);

  let isUser = redux_userData?.id !== eventByIdData?.UserId;

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    fetchAdsData();
  }, []);
  useEffect(() => {
    if (eventByIdData) {
      // setformvalues("startingRound", eventByIdData?.startingRound);
    }
  }, []);
  useEffect(() => {
    // if (newRoundOptions?.length > 0) {
    //   setRounds(newRoundOptions);
    // } else {
    //   setRounds(roundOptions);
    // }
  }, [newRoundOptions]);

  useEffect(() => {
    if (eventByIdData?.tournamentId && eventByIdData?.SportId) {
      fetchRoundData(
        eventByIdData?.tournamentId,
        eventByIdData?.SportId,
        eventByIdData?.id
      );
    }
  }, [eventByIdData?.tournamentId, eventByIdData?.SportId]);

  const onHandleTimeChange = newTime => {
    handleTimeChange(newTime);
  };

  const getFields = (list, field) => {
    return list.reduce(function(carry, item) {
      if (typeof item === "object" && field in item) {
        carry.push(item[field]);
      }
      return carry;
    }, []);
  };

  const handleConfirm = async () => {
    // setSelectedItem(item);
    handleOpenConfirmModal();
    // setSelectedId(id);
  };
  const handleOpenConfirmModal = () => {
    setOpenConfirm(true);
  };
  const handleConfirmClose = () => {
    setOpenConfirm(false);
  };
  const handleDoneModal = async () => {
    // setSelectedItem(item);
    handleOpenDoneModal();
    // setSelectedId(id);
  };
  const handleOpenDoneModal = () => {
    setOpenDoneModal(true);
  };
  const handleDoneClose = () => {
    setOpenDoneModal(false);
  };

  const fetchRoundData = async (tID, sID, compId) => {
    const date = moment(Date()).format("YYYY-MM-DD");
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/rounds?timezone=${timezone}&tournamentId=${tID}&SportId=${sID}&startDate=${date}&competitionId=${compId}`
      );
      if (status === 200) {
        let newdata = [];
        const filteredRound = data?.roundList?.filter(
          round => !data?.completedRound.includes(round?.round)
        );
        let track = filteredRound?.map(item => {
          newdata.push({
            label: `Round ${item?.round} ${
              item?.startTime
                ? "  (" +
                  moment(item?.startTime)
                    .tz(timezone)
                    .format("DD-MM-YYYY hh:mm A") +
                  ")"
                : ""
            }`,
            value: item?.round,
            roundStartDate: item?.startTime
          });
        });
        const startDate = newdata?.find(
          item => Number(item?.value) === Number(eventByIdData?.startingRound)
        );

        setDateToCheck(
          moment(startDate?.roundStartDate)
            .tz(timezone)
            .format("DD-MM-YYYY hh:mm A")
        );
        setRounds(newdata);
      }
    } catch (err) {}
  };
  const clearDate = () => {
    setDateSelected(null);
    setOpen(false);
  };
  const clearTime = () => {
    handleTimeChange(null);
    setTimeOpen(false);
  };

  const handleLiveDateChange = date => {
    setDateSelected(
      date
        ? moment(date)
            .tz(timezone)
            .format("YYYY-MM-DD")
        : null
    );
    // setselectedDate(
    //   date
    //     ? moment(date)
    //         .tz(timezone)
    //         .format("YYYY-MM-DD")
    //     : null
    // );
  };

  const ifNoTipData = data => {
    if (data !== null && data === "ZERO_SCORE_ROUND") {
      return "Zero Score for Round";
    } else if (data !== null && data === "LOWEST_SCORE_ROUND") {
      return "Lowest Score for Round";
    } else if (data !== null && data === "AWAY_TEAM_PICKED") {
      return "All Away Teams auto picked";
    } else if (data !== null && data === "HOME_TEAM_PICKED") {
      return "All Home Teams auto picked";
    } else {
      return "-";
    }
  };

  // const ifNoTipOptionsFunc = value => {
  //   if (value === 1) {
  //     return "ZERO_SCORE_ROUND";
  //   } else if (value === 2) {
  //     return "LOWEST_SCORE_ROUND";
  //   } else if (value === 3) {
  //     return "AWAY_TEAM_PICKED";
  //   } else {
  //     return "HOME_TEAM_PICKED";
  //   }
  // };

  const fetchAdsData = async () => {
    try {
      const passPageId =
        params?.SportId == 4 ? 41 : params?.SportId == 12 ? 42 : 43;
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=${passPageId}&type=web&timeZone=${timezone}`
      );
      if (status === 200) {
        setAdData(data?.result);
        // let ids = [];
        // let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
        //   ids.push(item?.id);
        // });
        // if (ids.length > 0) {
        //   handleAdsImression(ids);
        // }
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    // let ad = AdData?.filter((item) => {
    //   return item?.page_id === position;
    // });
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };

  const handleAdVisible = adId => {
    setVisibleAds(prevVisibleAds => [...prevVisibleAds, adId]);
  };

  const deleteOrLeaveComp = async type => {
    // const date = moment(Date()).format("YYYY-MM-DD");
    try {
      const { status, data } = await axiosInstance.delete(
        `/tipping/delete/${eventByIdData?.id}`
      );
      if (status === 200) {
        toast.success(
          type === "delete"
            ? "Competition Deleted"
            : "You have left the competition",
          {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored"
          }
        );

        setTimeout(() => {
          navigate("/tipscompetition/my-comps");
          // navigate(
          //   type === "delete"
          //     ? "/tipscompetition/my-comps"
          //     : `/tipscompetition/private-comps?code=${eventByIdData?.code}`
          // );
        }, 2000);
      } else {
        toast.error("An Error Occurred", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
    }
  };
  const ifNoTipOptionsFunc = value => {
    if (value === 1) {
      return "ZERO_SCORE_ROUND";
    } else if (value === 2) {
      return "LOWEST_SCORE_ROUND";
    } else if (value === 3) {
      return "AWAY_TEAM_PICKED";
    } else {
      return "HOME_TEAM_PICKED";
    }
  };
  const handleUpdate = async () => {
    setisLoading(true);
    let payload = {
      SportId: eventByIdData?.SportId,
      tournamentId: eventByIdData?.tournamentId,
      competitionName: formvalues?.compName,
      startingRound: formvalues?.startingRound,
      privacy: formvalues?.privacySettings,
      tipDeadline: formvalues?.tipDeadline,
      // cutOffDate:
      //   moment(formvalues?.selectDay).format("YYYY-MM-DD") +
      //   " " +
      //   moment(formvalues?.selectTime).format("hh:mm:ss"),
      noTip: formvalues?.ifNoTip,
      numberOfTippers: formvalues?.numberOfTippers,
      perfectRound: formvalues?.roundBonus === 1 ? true : false,
      JokerRound: formvalues?.jokerRound === 1 ? true : false,
      marginRound: formvalues?.marginSystem === 1 ? true : false,
      about: compAbout,
      MediaId: cropImage?.id
    };
    if (formvalues?.tipDeadline == "round") {
      payload = {
        ...payload,
        cutOffWeek: formvalues?.selectDay,
        cutOffTime: formvalues?.selectTime
          ? moment(formvalues?.selectTime)
              .tz(timezone)
              .format("HH:mm:ss")
          : ""
      };
    }
    // if (handleValidation()) {
    try {
      const { status, data } = await axiosInstance.put(
        `tipping/updateCompetition/${eventByIdData?.id}?timezone=${timezone}`,
        payload
      );
      if (status === 200) {
        toast.success("Competition Updated", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        getEventByID(stepperCount);
        setisLoading(false);
        setCompName("");
        setTimeout(() => {
          setOpenSocialShareModal(true);
        }, 2000);
      } else {
        toast.error("An Error Occurred", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
      }
    } catch (err) {
      setisLoading(false);
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored"
      });
    }
    // }
  };

  const handleImageModal = () => {
    setImageModalOpen(true);
  };

  const handleImageClose = () => {
    setImageModalOpen(false);
  };

  const getImage = data => {
    setCropImage(data);
  };
  return (
    <>
      <Box className="comp-details-sec">
        {loading ? (
          <div className="allsport-loader-center">
            <Loader />
          </div>
        ) : (
          <>
            {eventByIdData !== null && (
              <>
                <Box className="details-sec-header">
                  <Box className="details-header">
                    <Typography className="joined-txt">
                      Competition details
                    </Typography>
                    {/* transform: rotate(90deg); */}
                    {isUser ? (
                      <Button
                        disableElevation
                        disableFocusRipple
                        disableRipple
                        // type="submit"
                        className="leave-btn"
                        endIcon={<RightArrow className="right-arrow" />}
                        onClick={() => handleConfirm()}
                      >
                        Leave competition
                      </Button>
                    ) : (
                      <Button
                        disableElevation
                        disableFocusRipple
                        disableRipple
                        // type="submit"
                        className="leave-btn"
                        startIcon={<OrangeDelete />}
                        onClick={() => handleConfirm()}
                      >
                        Delete competition
                      </Button>
                    )}
                  </Box>

                  <Box className="details-sec">
                    {isUser ? (
                      <>
                        <Box className="details">
                          <Typography className="left">Sport:</Typography>
                          <Typography className="right">
                            {eventByIdData && eventByIdData?.Sport?.sportName}
                          </Typography>
                        </Box>
                        <Box className="details">
                          <Typography className="left">Comp code#:</Typography>
                          <Typography className="right">
                            {eventByIdData && eventByIdData?.code}
                          </Typography>
                        </Box>
                        <Box className="details">
                          <Typography className="left">League:</Typography>
                          <Typography className="right">
                            {eventByIdData && eventByIdData?.tournament?.name
                              ? eventByIdData?.tournament?.name
                              : "-"}
                          </Typography>
                        </Box>
                        <Box className="details">
                          <Typography className="left">
                            Competition name:
                          </Typography>
                          <Typography className="right">
                            {eventByIdData && eventByIdData?.competitionName}
                          </Typography>
                        </Box>
                        <Box className="details">
                          <Typography className="left">
                            Starting Round:
                          </Typography>
                          <Typography className="right">
                            {eventByIdData &&
                            eventByIdData?.startingRound != null
                              ? `Round ${eventByIdData?.startingRound}`
                              : "-"}
                          </Typography>
                        </Box>
                        <Box className="details">
                          <Typography className="left">Privacy:</Typography>
                          <Typography className="right">
                            {eventByIdData &&
                            eventByIdData?.privacy === "public"
                              ? "Public"
                              : "Private"}
                          </Typography>
                        </Box>
                        <Box className="details">
                          <Typography className="left">
                            Tip deadline:{" "}
                          </Typography>
                          <Typography className="right">
                            {eventByIdData &&
                            eventByIdData?.tipDeadline === "round"
                              ? "Round Based"
                              : "Game Based"}
                          </Typography>
                        </Box>
                        <Box className="details">
                          <Typography className="left">
                            If no tip is entered:
                          </Typography>
                          <Typography className="right">
                            {/* {eventByIdData && eventByIdData?.noTip} */}
                            {ifNoTipData(eventByIdData?.noTip)}
                          </Typography>
                        </Box>
                        {Boolean(eventByIdData?.numberOfTippers) && (
                          <Box className="details">
                            <Typography className="left">
                              Number of Tipsters:
                            </Typography>
                            <Typography className="right">
                              {eventByIdData && eventByIdData?.totalTipper}
                            </Typography>
                          </Box>
                        )}
                        <Box className="details">
                          <Typography className="left">
                            Perfect round Bonus:
                          </Typography>
                          <Typography className="right">
                            {eventByIdData && eventByIdData?.perfectRound === 1
                              ? "Yes"
                              : "No"}
                          </Typography>
                        </Box>
                        <Box className="details">
                          <Typography className="left">
                            Include Joker Round:
                          </Typography>
                          <Typography className="right">
                            {eventByIdData && eventByIdData?.jokerRound === 1
                              ? "Yes"
                              : "No"}
                          </Typography>
                        </Box>
                        <Box className="details">
                          <Typography className="left">
                            Include Margin System:
                          </Typography>
                          <Typography className="right">
                            {eventByIdData && eventByIdData?.marginRound === 1
                              ? "Yes"
                              : "No"}
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Formik
                          ref={formikRef}
                          enableReinitialize
                          initialValues={{
                            compName: eventByIdData?.competitionName,
                            startingRound: eventByIdData?.startingRound,
                            privacySettings: eventByIdData?.privacy,
                            tipDeadline: eventByIdData?.tipDeadline,
                            selectDay: eventByIdData?.cutOffWeek,
                            // moment
                            //   .utc(eventByIdData?.cutOffDate)
                            //   .local()
                            //   .format("YYYY-MM-DD")
                            selectTime: moment
                              .utc(`${eventByIdData?.cutOffTime}`, "HH:mm:ss")
                              .tz(timezone)
                              .format("YYYY-MM-DD HH:mm:ss"),
                            // moment
                            //   .utc(eventByIdData?.cutOffDate)
                            //   .local()
                            //   .format("hh:mm")
                            ifNoTip: eventByIdData?.noTip,
                            numberOfTippers: Boolean(
                              eventByIdData?.numberOfTippers
                            ),
                            roundBonus: eventByIdData?.perfectRound,
                            jokerRound: eventByIdData?.jokerRound,
                            marginSystem: eventByIdData?.marginRound
                          }}
                          validationSchema={Yup.object().shape({
                            compName: Yup.string()
                              .trim()
                              .required("Competition name is required"),
                            // .matches(
                            //   /^[^0-9]+$/,
                            //   "Numbers are not allowed for Competition Name"
                            // ),
                            // .test(
                            //   "compName",
                            //   "Only letters and Spacial characters are allowed for Competition Name",
                            //   value => value && value.match(/^[a-zA-Z\s'()-]+$/)
                            //   // value.match(
                            //   //   /^\p{L}[\p{L}'.\s]*(?:[\-]{0,1}[\p{L}.'\s]*\p{L}+)*$/gu
                            //   // )
                            // )
                            // startingRound: Yup.string().required(
                            //   "Starting Round is required"
                            // ),
                            privacySettings: Yup.string().required(
                              "Privacy settings is required"
                            ),
                            tipDeadline: Yup.string().required(
                              "Tip deadline is required"
                            ),
                            ifNoTip: Yup.string().required(
                              "This field is required"
                            ),
                            // selectDay: Yup.string().required("Choose a Day"),
                            // selectTime: Yup.string().required("Choose a Time"),
                            selectDay: Yup.string().when("tipDeadline", {
                              is: "round",
                              then: Yup.string().required("Choose a Day"),
                              otherwise: Yup.string()
                                .nullable()
                                .notRequired()
                            }),
                            selectTime: Yup.string()
                              .nullable()
                              .when("tipDeadline", {
                                is: "round",
                                then: Yup.string().required("Choose a Time"),
                                otherwise: Yup.string()
                                  .nullable()
                                  .notRequired()
                              }),
                            numberOfTippers: Yup.boolean()
                              .nullable()
                              .required("This field is required"),
                            roundBonus: Yup.string().required(
                              "This field is required"
                            ),
                            jokerRound: Yup.string().required(
                              "This field is required"
                            ),
                            marginSystem: Yup.string().required(
                              "This field is required"
                            )
                          })}
                          onSubmit={reqdata => {
                            // setformvalues({ ...formvalues, ...reqdata });
                            // handleValidation();
                            handleUpdate();
                          }}
                        >
                          {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                            setFieldValue
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <Box
                                className="comp-field-container"
                                style={{ marginTop: "21px" }}
                              >
                                <Box className="comp-image-name-wrap">
                                  <Box
                                    className="comp-image-wrap"
                                    onClick={() => handleImageModal()}
                                  >
                                    <img
                                      className="comp-image"
                                      src={
                                        cropImage?.filePath
                                          ? Config?.countryMediaURL +
                                            cropImage?.filePath
                                          : eventByIdData?.Media?.filePath
                                          ? Config?.countryMediaURL +
                                            eventByIdData?.Media?.filePath
                                          : compImage
                                      }
                                      alt="comp-image"
                                    />
                                    <AddButton className="add-button" />
                                  </Box>
                                  <Box
                                    className="comp-image-text-wrap"
                                    onClick={() => handleImageModal()}
                                  >
                                    <Typography className="image-text">
                                      Upload Comp Image
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box className="comp-details  about-comp-title">
                                  <Typography className="textfield-text left">
                                    About This Comp:
                                  </Typography>
                                  <Box className="field-container">
                                    <TextareaAutosize
                                      id="outlined-basic"
                                      variant="outlined"
                                      className="details-textarea-field"
                                      placeholder="- About -"
                                      name="compAbout"
                                      maxRows={3}
                                      minRows={2}
                                      onChange={e =>
                                        setCompAbout(e?.target?.value)
                                      }
                                      value={compAbout}
                                      // error={Boolean(touched?.compAbout && errors?.compAbout)}
                                      // helperText={touched?.compAbout ? errors?.compAbout : ""}
                                    />
                                  </Box>
                                </Box>
                                <Box className="details">
                                  <Typography className="left">
                                    Sport:
                                  </Typography>
                                  <Typography className="right">
                                    {eventByIdData &&
                                      eventByIdData?.Sport?.sportName}
                                  </Typography>
                                </Box>
                                <Box className="details">
                                  <Typography className="left">
                                    Comp code#:
                                  </Typography>
                                  <Typography className="right">
                                    {eventByIdData && eventByIdData?.code}
                                  </Typography>
                                </Box>
                                <Box className="details">
                                  <Typography className="left">
                                    League:
                                  </Typography>
                                  <Typography className="right">
                                    {eventByIdData &&
                                    eventByIdData?.tournament?.name
                                      ? eventByIdData?.tournament?.name
                                      : "-"}
                                  </Typography>
                                </Box>
                                {/* <Box className="details">
                                  <Typography className="left">
                                    Starting Round:
                                  </Typography>
                                  <Typography className="right">
                                    {eventByIdData &&
                                    eventByIdData?.startingRound != null
                                      ? `Round ${eventByIdData?.startingRound}`
                                      : "-"}
                                  </Typography>
                                </Box> */}
                                <Box className="comp-details mt-17">
                                  <Typography className="textfield-text left">
                                    Competition name:
                                    {/* <span className="danger">*</span>: */}
                                  </Typography>
                                  <Box className="field-container right">
                                    <TextField
                                      id="outlined-basic"
                                      variant="outlined"
                                      className="comp-textfield"
                                      placeholder="Choose a name"
                                      name="compName"
                                      // onChange={e =>
                                      //   setCompName(e?.target?.value)
                                      // }
                                      // value={compName}
                                      // error={Boolean(
                                      //   touched?.compName && errors?.compName
                                      // )}
                                      // helperText={
                                      //   touched?.compName
                                      //     ? errors?.compName
                                      //     : ""
                                      // }
                                      error={Boolean(
                                        touched?.compName && errors?.compName
                                      )}
                                      helperText={
                                        touched?.compName
                                          ? errors?.compName
                                          : ""
                                      }
                                      onChange={handleChange}
                                      value={values?.compName}
                                      // disabled
                                    />
                                  </Box>
                                </Box>
                                <Box className="comp-details">
                                  <Typography className="textfield-text left">
                                    Starting Round
                                    <span className="danger">*</span>:
                                  </Typography>
                                  <Box className="field-container right">
                                    {moment(
                                      dateToCheck,
                                      "DD-MM-YYYY hh:mm A"
                                    ).tz(timezone) < moment().tz(timezone) ? (
                                      <Typography className="right">
                                        {eventByIdData &&
                                        eventByIdData?.startingRound != null
                                          ? `Round ${eventByIdData?.startingRound}`
                                          : "-"}
                                      </Typography>
                                    ) : (
                                      <>
                                        <Select
                                          className="React sort-select"
                                          // value={
                                          //   selectedRound !== "" &&
                                          //   rounds?.find(item => {
                                          //     return item?.value === selectedRound;
                                          //   })
                                          // }
                                          // options={rounds}
                                          // onChange={e => setSelectedRound(e?.value)}
                                          classNamePrefix="select"
                                          placeholder="- Select Round -"
                                          components={{ DropdownIndicator }}
                                          onChange={e => {
                                            setFieldValue(
                                              "startingRound",
                                              e?.value
                                            );
                                          }}
                                          value={rounds?.find(
                                            item =>
                                              Number(item?.value) ===
                                              Number(values?.startingRound)
                                          )}
                                          options={rounds}
                                          isDisabled={
                                            moment(
                                              dateToCheck,
                                              "DD-MM-YYYY hh:mm A"
                                            ).tz(timezone) <
                                            moment().tz(timezone)
                                          }
                                        />
                                        <span className="text-danger">
                                          {rounds?.length === 0 && noRoundData
                                            ? noRoundData
                                            : ""}
                                        </span>
                                        <span className="text-danger">
                                          {touched?.startingRound &&
                                          errors?.startingRound
                                            ? errors?.startingRound
                                            : ""}
                                        </span>
                                      </>
                                    )}
                                  </Box>
                                </Box>
                                <Box className="comp-details">
                                  <Typography className="textfield-text left">
                                    Privacy settings:
                                    {/* <span className="danger">*</span>: */}
                                  </Typography>
                                  <Box className="field-container right">
                                    <Select
                                      className="React sort-select"
                                      // value={
                                      //   selectedPrivacy !== "" &&
                                      //   privacyOptions?.find(item => {
                                      //     return (
                                      //       item?.value === selectedPrivacy
                                      //     );
                                      //   })
                                      // }
                                      // onChange={e =>
                                      //   setSelectedPrivacy(e?.value)
                                      // }
                                      // options={privacyOptions}
                                      classNamePrefix="select"
                                      placeholder="- Select -"
                                      components={{ DropdownIndicator }}
                                      onChange={e => {
                                        setFieldValue(
                                          "privacySettings",
                                          e?.value
                                        );
                                      }}
                                      value={privacyOptions?.find(item => {
                                        return (
                                          item?.value == values?.privacySettings
                                        );
                                      })}
                                      options={privacyOptions}
                                      isDisabled={
                                        moment(
                                          dateToCheck,
                                          "DD-MM-YYYY hh:mm A"
                                        ).tz(timezone) < moment().tz(timezone)
                                      }
                                    />
                                    <span className="text-danger">
                                      {touched?.privacySettings &&
                                      errors?.privacySettings
                                        ? errors?.privacySettings
                                        : ""}
                                    </span>
                                  </Box>
                                </Box>
                                <Box className="comp-details">
                                  <Typography className="textfield-text left">
                                    Tip deadline:
                                    {/* <span className="danger">*</span>: */}
                                  </Typography>
                                  <Box className="field-container right">
                                    <Select
                                      className="React sort-select"
                                      // value={
                                      //   selectedTipDeadline !== "" &&
                                      //   tipDeadlineOptions?.find(item => {
                                      //     return (
                                      //       item?.value === selectedTipDeadline
                                      //     );
                                      //   })
                                      // }
                                      // onChange={e =>
                                      //   setSelectedTipDeadline(e?.value)
                                      // }
                                      // options={tipDeadlineOptions}
                                      classNamePrefix="select"
                                      placeholder="- Select Deadline -"
                                      components={{ DropdownIndicator }}
                                      onChange={e => {
                                        setFieldValue("tipDeadline", e?.value);
                                      }}
                                      value={tipDeadlineOptions?.find(item => {
                                        return (
                                          item?.value == values?.tipDeadline
                                        );
                                      })}
                                      options={tipDeadlineOptions}
                                      // isDisabled
                                    />
                                    <span className="text-danger">
                                      {touched?.tipDeadline &&
                                      errors?.tipDeadline
                                        ? errors?.tipDeadline
                                        : ""}
                                    </span>
                                  </Box>
                                </Box>
                                {values?.tipDeadline == "round" && (
                                  <>
                                    <Box className="comp-details">
                                      <Typography className="textfield-text left"></Typography>
                                      <Box className="day-time-section">
                                        <Box className="day-time-box">
                                          <Typography className="cutoff-txt">
                                            Select a day and time for cut off
                                          </Typography>

                                          <Box className="day-time">
                                            <Box className="mw-236 comp-date-selected-wrap">
                                              {/* <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <KeyboardDatePicker
                                            clearable
                                            autoOk
                                            // disableToolbar
                                            // variant="inline"
                                            format="dd/MM/yyyy"
                                            placeholder="- Select day -"
                                            margin="normal"
                                            id="date-picker-inline"
                                            inputVariant="outlined"
                                            // value={
                                            //   dateSelected ? dateSelected : null
                                            // }
                                            value={values?.selectDay}
                                            // onChange={e =>
                                            //   handleLiveDateChange(e)
                                            // }
                                            disabled={true}
                                            onChange={e =>
                                              setFieldValue("selectDay", e)
                                            }
                                            onClear={() => {
                                              setFieldValue("selectDay", "");
                                              setOpen(false);
                                            }}
                                            // onClear={clearDate}
                                            open={open}
                                            onOpen={() => setOpen(true)}
                                            onClose={() => setOpen(false)}
                                            views={["year", "month", "date"]}
                                            KeyboardButtonProps={{
                                              "aria-label": "Select Date"
                                            }}
                                            keyboardIcon={
                                              <Datepicker className="keyboard-icon" />
                                            }
                                            // className="comp-comp-details-search-picker"
                                          />
                                        </MuiPickersUtilsProvider> */}
                                              <Box className="field-container">
                                                <Select
                                                  className="React sort-select"
                                                  // value={rounds?.find(item => {
                                                  //   return (
                                                  //     item ==
                                                  //     eventByIdData?.startingRound
                                                  //   );
                                                  // })}
                                                  // options={tipDeadlineOptions}
                                                  value={
                                                    // selectedDay !== "" &&
                                                    dayOptions?.find(item => {
                                                      return (
                                                        item?.label ===
                                                        values?.selectDay
                                                      );
                                                    })
                                                  }
                                                  onChange={e =>
                                                    setFieldValue(
                                                      "selectDay",
                                                      e?.label
                                                    )
                                                  }
                                                  // value={eventByIdData?.cutOffWeek}
                                                  // isDisabled={true}
                                                  options={dayOptions}
                                                  classNamePrefix="select"
                                                  placeholder="Select Day"
                                                  components={{
                                                    DropdownIndicator
                                                  }}
                                                />
                                              </Box>
                                              <span className="text-danger">
                                                {touched?.selectDay &&
                                                errors?.selectDay
                                                  ? errors?.selectDay
                                                  : ""}
                                              </span>
                                            </Box>
                                            <Box
                                              className="mw-236 comp-date-selected-wrap border-gray"
                                              style={{ position: "relative" }}
                                            >
                                              <MuiPickersUtilsProvider
                                                utils={DateFnsUtils}
                                              >
                                                <TimePicker
                                                  clearable
                                                  autoOk
                                                  // onClear={clearTime}
                                                  // onClear={() => {
                                                  //   setFieldValue("selectTime", "");
                                                  //   setTimeOpen(false);
                                                  // }}
                                                  placeholder="--:--"
                                                  value={values?.selectTime}
                                                  onChange={e =>
                                                    setFieldValue(
                                                      "selectTime",
                                                      e
                                                    )
                                                  }
                                                  // disabled={true}
                                                  // ampm={false}
                                                  // open={timeOpen}
                                                  // onOpen={() => setTimeOpen(true)}
                                                  // onClose={() => setTimeOpen(false)}
                                                  format="hh:mm"
                                                  TextFieldComponent={props => (
                                                    <TextField
                                                      {...props}
                                                      // className="comp-comp-details-search-picker"
                                                      InputProps={{
                                                        endAdornment: (
                                                          <InputAdornment position="end">
                                                            <IconButton>
                                                              <Timepicker className="keyboard-icon" />
                                                            </IconButton>
                                                          </InputAdornment>
                                                        )
                                                      }}
                                                    />
                                                  )}
                                                />
                                              </MuiPickersUtilsProvider>

                                              {/* <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            className="comp-textfield"
                                            placeholder="Choose a name"
                                            name="compName"
                                            value={
                                              eventByIdData &&
                                              eventByIdData?.cutOffTime
                                                ? moment
                                                    .utc(
                                                      `${eventByIdData?.cutOffWeek} ${eventByIdData?.cutOffTime}`,
                                                      "dddd HH:mm:ss"
                                                    )
                                                    .tz(timezone)
                                                    .format("HH:mm:ss")
                                                : null
                                            }
                                            // isDisabled={true}
                                          /> */}
                                              <span
                                                className="text-danger"
                                                style={{
                                                  position: "absolute",
                                                  bottom: "-20px"
                                                }}
                                              >
                                                {touched?.selectTime &&
                                                errors?.selectTime
                                                  ? errors?.selectTime
                                                  : ""}
                                              </span>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </>
                                )}
                                <Box className="comp-details">
                                  <Typography className="textfield-text left">
                                    If no tip is entered:
                                    {/* <span className="danger">*</span>: */}
                                  </Typography>
                                  <Box className="field-container right">
                                    <Select
                                      className="React sort-select"
                                      // value={
                                      //   selectedNoTip !== "" &&
                                      //   ifNoTipOptions?.find(item => {
                                      //     return item?.value === selectedNoTip;
                                      //   })
                                      // }
                                      // onChange={e => setSelectedNoTip(e?.value)}
                                      // options={ifNoTipOptions}
                                      classNamePrefix="select"
                                      placeholder="- Select -"
                                      components={{ DropdownIndicator }}
                                      onChange={e => {
                                        setFieldValue("ifNoTip", e?.value);
                                      }}
                                      value={ifNoTipOptions?.find(item => {
                                        return item?.value == values?.ifNoTip;
                                      })}
                                      options={ifNoTipOptions}
                                      isDisabled={
                                        moment(
                                          dateToCheck,
                                          "DD-MM-YYYY hh:mm A"
                                        ).tz(timezone) < moment().tz(timezone)
                                      }
                                    />
                                    <span className="text-danger">
                                      {touched?.ifNoTip && errors?.ifNoTip
                                        ? errors?.ifNoTip
                                        : ""}
                                    </span>
                                  </Box>
                                </Box>
                                <Box className="comp-details tipster-number">
                                  <Typography className="textfield-text left">
                                    Number of Tipsters:
                                    {/* <span className="danger">*</span>: */}
                                  </Typography>
                                  <Box className="field-container right">
                                    <Typography className="tipper-number">
                                      {eventByIdData?.totalTipper}
                                    </Typography>
                                    <Box className="Filteritemlist-wrap">
                                      <Box className="field-container">
                                        <Box className="filter-list-racing">
                                          <ul className="Filteritemlist-racing">
                                            {isTipsterShown?.length > 0 &&
                                              isTipsterShown?.map((obj, i) => (
                                                <li key={i}>
                                                  <FormControl>
                                                    <label>
                                                      <Checkbox
                                                        className="filter-racing"
                                                        icon={<Unchecked />}
                                                        checkedIcon={
                                                          <Checked />
                                                        }
                                                        name="filter"
                                                        onChange={() =>
                                                          // setRoundBonusOption(
                                                          //   obj?.id
                                                          // )
                                                          setFieldValue(
                                                            "numberOfTippers",
                                                            obj.value
                                                          )
                                                        }
                                                        checked={
                                                          values?.numberOfTippers ===
                                                          obj?.value
                                                        }
                                                        disabled={
                                                          moment(
                                                            dateToCheck,
                                                            "DD-MM-YYYY hh:mm A"
                                                          ).tz(timezone) <
                                                          moment().tz(timezone)
                                                        }
                                                      />
                                                      {obj?.isTipsterShown}
                                                    </label>
                                                  </FormControl>
                                                </li>
                                              ))}
                                          </ul>
                                        </Box>
                                        <span className="text-danger">
                                          {touched?.numberOfTippers &&
                                          errors?.numberOfTippers
                                            ? errors?.numberOfTippers
                                            : ""}
                                        </span>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box className="comp-details">
                                  <Typography className="textfield-text left">
                                    Perfect round Bonus:
                                    {/* <span className="danger">*</span>: */}
                                  </Typography>
                                  <Box className="Filteritemlist-wrap">
                                    <Box className="field-container">
                                      <Box className="filter-list-racing">
                                        <ul className="Filteritemlist-racing">
                                          {roundBonus?.length > 0 &&
                                            roundBonus?.map((obj, i) => (
                                              <li key={i}>
                                                <FormControl>
                                                  <label>
                                                    <Checkbox
                                                      className="filter-racing"
                                                      icon={<Unchecked />}
                                                      checkedIcon={<Checked />}
                                                      name="filter"
                                                      onChange={() =>
                                                        // setRoundBonusOption(
                                                        //   obj?.id
                                                        // )
                                                        setFieldValue(
                                                          "roundBonus",
                                                          obj.id
                                                        )
                                                      }
                                                      checked={
                                                        values?.roundBonus ===
                                                        obj?.id
                                                      }
                                                      disabled={
                                                        moment(
                                                          dateToCheck,
                                                          "DD-MM-YYYY hh:mm A"
                                                        ).tz(timezone) <
                                                        moment().tz(timezone)
                                                      }
                                                    />
                                                    {obj?.Roundvalue}
                                                  </label>
                                                </FormControl>
                                              </li>
                                            ))}
                                        </ul>
                                      </Box>
                                      <span className="text-danger">
                                        {touched?.roundBonus &&
                                        errors?.roundBonus
                                          ? errors?.roundBonus
                                          : ""}
                                      </span>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box className="comp-details">
                                  <Typography className="textfield-text left">
                                    Include Joker Round:
                                    {/* <span className="danger">*</span>: */}
                                  </Typography>
                                  <Box className="Filteritemlist-wrap">
                                    <Box className="field-container">
                                      <Box className="filter-list-racing">
                                        <ul className="Filteritemlist-racing">
                                          {jokerBonus?.length > 0 &&
                                            jokerBonus?.map((obj, i) => (
                                              <li key={i}>
                                                <FormControl>
                                                  <label>
                                                    <Checkbox
                                                      className="filter-racing"
                                                      icon={<Unchecked />}
                                                      checkedIcon={<Checked />}
                                                      name="filter"
                                                      onChange={() =>
                                                        // setJokerBonusOption(
                                                        //   obj?.id
                                                        // )
                                                        setFieldValue(
                                                          "jokerRound",
                                                          obj.id
                                                        )
                                                      }
                                                      checked={
                                                        values?.jokerRound ===
                                                        obj?.id
                                                        // jokerBonusOption ===
                                                        // obj?.id
                                                      }
                                                      disabled={
                                                        moment(
                                                          dateToCheck,
                                                          "DD-MM-YYYY hh:mm A"
                                                        ).tz(timezone) <
                                                        moment().tz(timezone)
                                                      }
                                                    />
                                                    {obj?.Jokervalue}
                                                  </label>
                                                </FormControl>
                                              </li>
                                            ))}
                                        </ul>
                                      </Box>
                                      <span className="text-danger">
                                        {touched?.jokerRound &&
                                        errors?.jokerRound
                                          ? errors?.jokerRound
                                          : ""}
                                      </span>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box className="comp-details">
                                  <Typography className="textfield-text left">
                                    Include Margin System:
                                    {/* <span className="danger">*</span>: */}
                                  </Typography>
                                  <Box className="Filteritemlist-wrap">
                                    <Box className="field-container">
                                      <Box className="filter-list-racing">
                                        <ul className="Filteritemlist-racing">
                                          {marginSystem?.length > 0 &&
                                            marginSystem?.map((obj, i) => (
                                              <li key={i}>
                                                <FormControl>
                                                  <label>
                                                    <Checkbox
                                                      className="filter-racing"
                                                      icon={<Unchecked />}
                                                      checkedIcon={<Checked />}
                                                      name="filter"
                                                      onChange={() =>
                                                        // setMarginSystemOption(
                                                        //   obj.id
                                                        // )
                                                        setFieldValue(
                                                          "marginSystem",
                                                          obj?.id
                                                        )
                                                      }
                                                      checked={
                                                        values?.marginSystem ===
                                                        obj?.id
                                                        // marginSystemOption ===
                                                        // obj?.id
                                                      }
                                                      disabled={
                                                        moment(
                                                          dateToCheck,
                                                          "DD-MM-YYYY hh:mm A"
                                                        ).tz(timezone) <
                                                        moment().tz(timezone)
                                                      }
                                                    />
                                                    {obj?.MarginSystemvalue}
                                                  </label>
                                                </FormControl>
                                              </li>
                                            ))}
                                        </ul>
                                      </Box>
                                      <span className="text-danger">
                                        {touched?.marginSystem &&
                                        errors?.marginSystem
                                          ? errors?.marginSystem
                                          : ""}
                                      </span>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box className="comp-button">
                                  <Box className="comp-btn">
                                    <Button
                                      variant="contained"
                                      className="btn-save"
                                      type="submit"
                                    >
                                      {isLoading ? (
                                        <CircularProgress
                                          size={24}
                                          color="inherit"
                                        />
                                      ) : (
                                        "Update changes"
                                      )}
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                              {setformvalues(values)}
                            </form>
                          )}
                        </Formik>
                      </>
                    )}
                  </Box>
                </Box>
              </>
            )
            // : (
            //   <Box
            //     style={{
            //       textAlign: "center"
            //     }}
            //   >
            //     <NoDataComp />
            //   </Box>
            // )
            }
          </>
        )}
      </Box>
      <DialogBox
        open={openConfirm}
        onClose={handleConfirmClose}
        handleClose={handleConfirmClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="delete-title">
                {isUser
                  ? "Are you sure you want to leave the competition?"
                  : "Are you sure you want to delete the competition?"}
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel blackbook-delete"
                  onClick={e => {
                    handleConfirmClose();
                    handleDoneModal();
                    deleteOrLeaveComp(isUser ? "leave" : "delete");
                  }}
                >
                  Yes
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleConfirmClose()}
                >
                  No
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
      <DialogBox
        open={openDoneModal}
        onClose={handleDoneClose}
        handleClose={handleDoneClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox">
              <Typography className="restriction-title">Success!</Typography>
              <Typography className="restriction-text">
                {isUser
                  ? `You have been removed from the competition. All of your entries are no longer available.`
                  : `You have deleted the tipping competition ${eventByIdData?.tournament?.name}. All of your entries and member tips are no longer available.`}
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-Close-Modal btn-cancel blackbook-delete"
                  onClick={e => {
                    handleDoneClose();
                  }}
                >
                  Close
                </Button>
                {/* <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => handleConfirmClose()}
                >
                  No
                </Button> */}
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
      {/* <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      /> */}
      <UploadImageModal
        open={imageModalOpen}
        handleClose={handleImageClose}
        title={"Upload Comp Image"}
        closeIcon={false}
        getImage={getImage}
        //  listingFunction={() =>
        //    setTimeout(() => {
        //      getEventByID(stepperCount);
        //    }, 3000)
        //  }
      />
      {fetchAds(
        1,
        "100%",
        Config?.release == "IN" ? BannerIndia : tippingBelow
      )}
    </>
  );
};

export default CompDetailsTab;
